import { useTranslation } from "react-i18next";

import { FILE_SERVICE } from "config/common";

import { AuthRoleCheck } from "components/AuthRoleCheck";
import { Avatar } from "components/Avatar";
import { Card } from "components/Card/Card";
import { Pill } from "components/Pill";
import { Tabs } from "components/Tabs";

import { formatCurrency } from "lib/formatNumber";

import { CustomerProfileInformation } from "../CustomerProfileInformation";
import { CustomerSegmentDropdown } from "../CustomerSegmentDropdown";
import styles from "./CustomerProfile.module.scss";

type Props = {
  customer: SingleCustomerRes["customer"];
};

export function CustomerProfile({ customer }: Props) {
  const {
    customerNo,
    customerName,
    clientName,
    distributionEmailAddress,
    mobilePhoneNo,
    customerAddress,
    totalRemainingBalance,
    currency,
  } = customer;
  const { t } = useTranslation();

  return (
    <div className={styles.customerProfile}>
      <Card>
        <div className={styles.customerProfileMainInfo}>
          <Avatar userName={customerName} toShowUsername={true} />

          <AuthRoleCheck role="client-operator">
            <div className={styles.customerProfileClientOf}>
              <p>{t("pages.customer.clientOf")}</p>
              <img src={`${FILE_SERVICE}/${clientName}-logo.png`} />
            </div>
          </AuthRoleCheck>

          {/* Action buttons */}
          <div className={styles.customerProfileActionButtons}>
            <div>
              <p>{t("pages.customersCases.tableColumns.customerNo")}</p>
              <Pill text={customerNo} />
            </div>
            <div>
              <p>{t("pages.customersCases.tableColumns.profile")}</p>
              <CustomerSegmentDropdown />
            </div>
            <div>
              <p>{t("pages.customersCases.tableColumns.balance")}</p>
              <Pill text={formatCurrency(totalRemainingBalance, currency)} />
            </div>
          </div>

          {/* Tabs */}
          <div className={styles.customerProfileTabs}>
            <Tabs
              fill
              tabs={[
                {
                  title: t("pages.customer.customerInformation"),
                  content: (
                    <CustomerProfileInformation
                      email={distributionEmailAddress}
                      phone={mobilePhoneNo}
                      country={customerAddress.countryCode}
                      address={{
                        city: customerAddress.city,
                        street: customerAddress.addressLine1,
                        postcode: customerAddress.zipCode,
                      }}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </Card>
    </div>
  );
}
