import { Link } from "react-router-dom";

import { classNames } from "lib/classNames";

import styles from "./Pill.module.scss";

type Props = {
  text: string | React.ReactNode;
  intent?: "Dark" | "Light" | "Active" | "Purple" | "Link";
  color?: string;
  href?: string;
  label?: string;
};

export function Pill({ text, label, intent = "Light", color, href }: Props) {
  const pill = (
    <div className={styles.pill}>
      {label && <p className={styles.label}>{label}</p>}
      <p
        className={classNames(styles.pillContainer, styles[`pill${intent}`])}
        style={{ color }}
      >
        {text}
      </p>
    </div>
  );

  if (href) {
    return (
      <Link to={href} style={{ textDecoration: "none" }}>
        {pill}
      </Link>
    );
  }

  return pill;
}
