import { store } from "app/store";

import { setTheme } from "lib/slices/themeSlice";

// Define types for the theme and user role
type Theme = "ecollect" | "inio";

interface DebugMenu {
  setTheme: (theme: Theme) => void;
}

declare global {
  interface Window {
    debug: DebugMenu;
  }
}

// Adds custom commands to console for debug purposes.
const debugMenu = () => {
  const THEMES: Theme[] = ["ecollect", "inio"];

  window.debug = {
    setTheme: (theme: Theme) => {
      if (!THEMES.includes(theme)) {
        console.error(`Theme ${theme} does not exist!`);
        return;
      }

      store.dispatch(setTheme(theme));
    },
  };
};

export default debugMenu;
