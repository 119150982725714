import { useTranslation } from "react-i18next";

import validator from "@rjsf/validator-ajv8";

import { Form } from "components/Form";

export function AdditionalInfoForm() {
  const { t } = useTranslation();

  const formData = {
    title: t("newClaim.contacts"),
    iconName: "handheld",
    uiFormSchema: {
      "ui:submitButtonOptions": {
        parentProps: {
          hidden: true,
        },
      },
      contacts: {
        title: t("newClaim.contacts"),
        iconName: "contactphonebook",
        items: {
          type: {
            "ui:widget": "select",
            "ui:placeholder": t("newClaim.type"),
            "ui:width": "50%",
          },
          value: {
            "ui:widget": "text",
            "ui:width": "50%",
          },
          email: {
            "ui:widget": "email",
            "ui:autocomplete": "email",
            "ui:placeholder": "johndoe@example.com",
          },
          mobile: {
            "ui:widget": "text",
            "ui:autocomplete": "tel",
            "ui:placeholder": "+1 (555) 000-0000",
            "ui:width": "50%",
          },
          landline: {
            "ui:widget": "text",
            "ui:autocomplete": "tel",
            "ui:placeholder": "+1 (555) 000-0000",
            "ui:width": "50%",
          },
        },
      },
      addresses: {
        title: t("newClaim.addresses"),
        iconName: "home1",
        items: {
          country: {
            "ui:widget": "select",
            "ui:placeholder": t("newClaim.country"),
            "ui:width": "50%",
          },
          zip: {
            "ui:widget": "text",
            "ui:autocomplete": "postal-code",
            "ui:width": "50%",
          },
          city: {
            "ui:widget": "text",
            "ui:autocomplete": "address-level2",
            "ui:width": "50%",
          },
          addressLine: {
            "ui:widget": "text",
            "ui:autocomplete": "address-line1",
            "ui:width": "50%",
          },
        },
      },
      bankAccounts: {
        title: t("newClaim.bankAccounts"),
        iconName: "bank",
        items: {
          accountHolder: {
            "ui:widget": "text",
            "ui:width": "50%",
          },
          bicSwift: {
            "ui:widget": "text",
            "ui:width": "50%",
          },
          iban: {
            "ui:widget": "text",
            "ui:autocomplete": "email",
          },
        },
      },
    },
    formSchema: {
      type: "object",
      required: ["type", "value", "email", "mobile", "landline"],
      properties: {
        contacts: {
          type: "array",
          default: [{}],
          items: {
            type: "object",
            title: "",
            minItems: 1,
            required: ["type", "value", "email", "mobile", "landline"],
            properties: {
              type: {
                type: "string",
                title: t("newClaim.type"),
                enum: ["option1", "option2"],
              },
              value: {
                type: "string",
                title: t("newClaim.value"),
              },
              email: {
                type: "string",
                title: t("email"),
              },
              mobile: {
                type: "string",
                title: t("newClaim.phoneNumber"),
              },
              landline: {
                type: "string",
                title: t("newClaim.landline"),
              },
            },
          },
        },
        addresses: {
          type: "array",
          default: [{}],
          items: {
            type: "object",
            title: "",
            minItems: 1,
            required: ["type", "value", "email", "mobile", "landline"],
            properties: {
              country: {
                type: "string",
                title: t("Country"),
                enum: t("countries", { returnObjects: true }),
              },
              zip: {
                type: "string",
                title: t("newClaim.zip"),
              },
              city: {
                type: "string",
                title: t("newClaim.city"),
              },
              addressLine: {
                type: "string",
                title: t("newClaim.addressLine"),
              },
            },
          },
        },
        bankAccounts: {
          type: "array",
          default: [{}],
          items: {
            type: "object",
            title: "",
            minItems: 1,
            required: ["type", "value", "email", "mobile", "landline"],
            properties: {
              accountHolder: {
                type: "string",
                title: t("newClaim.accountHolder"),
              },
              bicSwift: {
                type: "string",
                title: t("newClaim.bicSwift"),
              },
              iban: {
                type: "string",
                title: t("newClaim.iban"),
              },
            },
          },
        },
      },
    },
  };

  return (
    <Form
      uiSchema={formData.uiFormSchema}
      schema={formData.formSchema}
      validator={validator}
      onChange={(e: any) => console.log(e)}
    />
  );
}
