import { useEffect, useState } from "react";

import styles from "./Range.module.scss";

export function Range(props: any) {
  const min = props.min ?? 0;
  const max = props.max ?? 100;
  const range = max - min;

  const [sliderValue, setSliderValue] = useState(props.value ?? min);
  const [isMoving, setIsMoving] = useState(false);

  useEffect(() => {
    setSliderValue(props.value);
  }, [props.value]);

  const onInput = ({ target }: any) => {
    setSliderValue(target.value);
  };

  const onMouseDown = () => setIsMoving(true);
  const onMouseUp = () => setIsMoving(false);

  const gradientPercentage = ((sliderValue - min) / range) * 100;

  if (!props.value) {
    return <></>;
  }

  return (
    <div className={styles.rangeContainer}>
      <input
        {...props}
        type="range"
        onInput={onInput}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        style={{
          background: `linear-gradient(to right, var(--color-primary) ${gradientPercentage}%, #eaecf0 ${gradientPercentage}%)`,
        }}
        value={sliderValue ?? min}
      />
      {isMoving && (
        <output
          className={styles.rangeValue}
          style={{
            left: `calc(${gradientPercentage}% + (${
              8 - gradientPercentage * 0.15
            }px))`,
          }}
        >
          {sliderValue}
        </output>
      )}
    </div>
  );
}
