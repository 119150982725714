import { ChangeEvent } from "react";

import { schemaRequiresTrueValue, WidgetProps } from "@rjsf/utils";

import styles from "./CheckboxWidget.module.scss";

export function CheckboxWidget(props: WidgetProps) {
  const {
    schema,
    id,
    name,
    value,
    disabled,
    readonly,
    label,
    autofocus: autoFocus,
    onChange,
    onBlur,
    onFocus,
  } = props;

  // Because an unchecked checkbox will cause html5 validation to fail, only add
  // the "required" attribute if the field value must be "true", due to the
  // "const" or "enum" keywords in the rjsf schema
  const required = schema ? schemaRequiresTrueValue(schema) : false;

  const _onChange = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) =>
    onChange?.(checked);
  const _onBlur = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    onBlur?.(id, value);
  const _onFocus = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    onFocus?.(id, value);

  return (
    <div className={styles.checkboxesContainer}>
      <label>
        <input
          id={id}
          name={name}
          type="checkbox"
          disabled={disabled || readonly}
          required={required}
          autoFocus={autoFocus}
          checked={value}
          onChange={_onChange}
          onBlur={_onBlur}
          onFocus={_onFocus}
        />
        {label}
      </label>
    </div>
  );
}
