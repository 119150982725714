import { initReactI18next } from "react-i18next";

import localeDe from "i18n/de.json";
import localeEn from "i18n/en.json";
import i18n from "i18next";

import { appStorage } from "lib/localStorage";

export type SupportedLanguages = keyof typeof resources;

export const resources = {
  en: { translation: localeEn },
  de: { translation: localeDe },
};

i18n.use(initReactI18next).init({
  resources,
  lng: appStorage.getItem<SupportedLanguages>("language") ?? "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
