/**
 * Filter Function for Tanstack Table
 * Checks if the date is within the given date range.
 *
 * @param {Object} row - The row object containing the date to check.
 * @param {string} columnId - The ID of the column containing the date.
 * @param {Array<Date>} value - An array containing two elements: the start and end dates of the range.
 *                              Either element can be null to represent an open-ended range.
 * @returns {boolean} - Returns true if the date in the row is within the range (inclusive),
 *                      false if it's outside the range, or if the date is missing when a range boundary is defined.
 */
export function inDateRange(
  row: { getValue: (columnId: string) => string | Date },
  columnId: string,
  value: [Date | null, Date | null],
): boolean {
  const date = new Date(row.getValue(columnId));
  const [start, end] = value;

  if ((start || end) && isNaN(date.getTime())) {
    return false;
  }

  if (start && !end) {
    return date.toDateString() === start.toDateString();
  } else if (!start && end) {
    return date.getTime() <= end.getTime();
  } else if (start && end) {
    return (
      (date.getTime() >= start.getTime() && date.getTime() < end.getTime()) ||
      date.toDateString() === end.toDateString()
    );
  } else {
    return true;
  }
}

// Required for RJSF validation
export function formatDateToYYYYMMDD(date: Date): string {
  date = new Date(date);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
}

// Formats date range to 12 - 16 December 2023 or 12 December 2023 for single input
export function formatDateRange(startDate: Date, endDate: Date | null): string {
  startDate = new Date(startDate);
  endDate = endDate ? new Date(endDate) : null;

  if (!endDate) {
    return startDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  }

  let formattedStartDate: string;
  let formattedEndDate: string;

  if (
    startDate.getMonth() === endDate.getMonth() &&
    startDate.getFullYear() === endDate.getFullYear()
  ) {
    formattedStartDate = startDate.toLocaleDateString("en-GB", {
      day: "numeric",
    });

    formattedEndDate = endDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  } else {
    formattedStartDate = startDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    formattedEndDate = endDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  }

  return `${formattedStartDate} - ${formattedEndDate}`;
}

export function formatDateToString(dateString: string): string {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return date.toLocaleDateString("en-US", options);
}
