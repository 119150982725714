import { useTranslation } from "react-i18next";

import { RInioButton } from "ui-core";

import { Avatar } from "components/Avatar";
import { Card } from "components/Card/Card";
import { Dropdown } from "components/Dropdowns";
import { CustomerSegmentDropdown } from "components/PageCustomer/CustomerSegmentDropdown";
import { Pill } from "components/Pill";
import { Skeleton } from "components/Skeleton";
import { Text } from "components/Text";

import { formatCurrency } from "lib/formatNumber";
import { buildAddressString } from "lib/helpers";

import styles from "./CustomerCard.module.scss";

type CustomerAvatarCardProps = {
  caseData?: SingleCaseRes;
  isLoading: boolean;
};

export function CustomerCard({ caseData, isLoading }: CustomerAvatarCardProps) {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <section style={{ width: "42%", height: "100vh" }}>
        <Skeleton width={"100%"} height={"100%"} />
      </section>
    );
  }

  if (!caseData) {
    return;
  }

  return (
    <section style={{ width: "44%" }}>
      <Card>
        <div className={styles.customerAvatarCardWrapper}>
          <Avatar
            userName={caseData.customer.customerName}
            toShowUsername={true}
          />

          <div className={styles.customerAvatarCardActionButtons}>
            <div>
              <Pill
                label={t("pages.customersCases.tableColumns.customerNo")}
                text={caseData.customer.customerNo}
                href={`/customer/${caseData.customer.id}`}
                intent="Link"
              />
            </div>

            <div>
              <div>
                <p>{t("pages.customersCases.tableColumns.profile")}</p>
                <CustomerSegmentDropdown />
              </div>
              <div>
                <Pill
                  label={t("pages.customersCases.tableColumns.balance")}
                  text={formatCurrency(caseData.invoiceTotalRemainingBalance)}
                />
              </div>
            </div>
          </div>

          <div className={styles.caseManagment}>
            <Text as="h4" style="h5" strong>
              {t("pages.case.caseManagement")}
            </Text>

            <div className={styles.btnGrid}>
              <RInioButton disabled text="Withdraw Case" icon="fileCross" />
              <RInioButton disabled text="Pause Case" icon="pause" />
              <RInioButton disabled text="Dispute Case" icon="chatBubble" />
              <RInioButton disabled text="Goodwill Waive" icon="giveGift" />
              <RInioButton disabled text="Credit Amount" icon="addMoney" />
              <RInioButton disabled text="Credit Loss" icon="graphArrowDown" />
              <span style={{ gridColumn: "span 2" }}>
                <RInioButton
                  disabled
                  text="Postpone Due Date"
                  icon="calendar"
                />
              </span>
            </div>
          </div>

          <Dropdown
            btnElement={
              <span className={styles.customerInformationDropdownTitle}>
                {t("pages.case.customerInformation")}
              </span>
            }
            icon={{
              name: "informationcircle",
            }}
          >
            <div className={styles.customerInformationDropdownMenu}>
              <div>
                <h4>{t("pages.case.email")}</h4>
                <p>{caseData.customer.customerName}</p>
              </div>
              <div>
                <h4>{t("pages.case.phoneNumber")}</h4>
                <p>{caseData.customer.mobilePhoneNo}</p>
              </div>
              <div>
                <h4>{t("pages.case.country")}</h4>
                <p>{caseData.customer.customerAddress.countryCode}</p>
              </div>
              <div>
                <h4>{t("pages.case.address")}</h4>
                <p>
                  {buildAddressString(
                    {
                      city: caseData.customer.customerAddress.city,
                      street: caseData.customer.customerAddress.addressLine1,
                      postcode: caseData.customer.customerAddress.zipCode,
                    },
                    true,
                  )}
                </p>
              </div>
            </div>
          </Dropdown>
        </div>
      </Card>
    </section>
  );
}
