import { icons } from "assets/icons";

export { Modal } from "./Modal";
export { ModalAlert } from "./ModalAlert/ModalAlert";
export { ModalAction } from "./ModalAction/ModalAction";

export const getIcon = (intent: string): keyof typeof icons | undefined => {
  switch (intent) {
    case "info":
      return "informationcircle";
    case "danger":
      return "alertCircle";
    case "success":
      return "checkCircle";
    default:
      return undefined;
  }
};
