import { Icon } from "components/Icon";

import { classNames } from "lib/classNames";

import styles from "./Section.module.scss";

export function Section(props: any & { fullWidth?: boolean }) {
  const iconName = props.uiSchema["ui:icon"];
  const errorProps = props.uiSchema["ui:error"] || {};

  return (
    <fieldset
      className={classNames(styles.fieldset, {
        [styles.fieldsetFullWidth]: props.fullWidth,
      })}
    >
      {errorProps?.message && <p>{errorProps.message}</p>}
      <div
        className={
          props.uiSchema?.["ui:options"]?.hideTitle ? "" : styles.titleContainer
        }
      >
        {iconName && <Icon name={iconName} />}
        {!props.uiSchema?.["ui:options"]?.hideTitle && <p>{props.title}</p>}
      </div>
      {props.description}
      {props?.properties?.map((element: any, i: number) => (
        <div
          key={i}
          style={{
            width: element.content.props.uiSchema?.["ui:width"] ?? "100%",
          }}
        >
          {element.content}
        </div>
      ))}
    </fieldset>
  );
}
