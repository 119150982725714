import { useState } from "react";
import { useTranslation } from "react-i18next";

import validator from "@rjsf/validator-ajv8";

import { Form } from "components/Form";

import { slugify } from "lib/helpers";
import {
  useCreateMemberMutation,
  useEditMemberMutation,
  useGetClientsQuery,
  useGetMembersQuery,
} from "lib/slices/clientServiceAPISlice";
import { Button } from "components/Button";
import { PasswordGenerateWidget } from "components/Form/PasswordGenerateWidget";

import styles from "./FormCreateUser.module.scss";

type Props = {
  defaultValues?: Member;
  closeModal?: () => void;
};

export function FormCreateUser({ defaultValues, closeModal }: Props) {
  const { t } = useTranslation();
  const { data: clients } = useGetClientsQuery();
  const [createMember, { isLoading }] = useCreateMemberMutation();
  const [editMember, { isLoading: isLoadingEdit }] = useEditMemberMutation();
  const [errors, setErrors] = useState();
  const { refetch } = useGetMembersQuery();

  const formData = {
    onSubmit: (data: any) => {
      if (defaultValues) {
        editMember({ ...data.formData, userId: defaultValues.id })
          .unwrap()
          .then(async () => {
            await refetch();
            closeModal?.();
          })
          .catch((error) => setErrors(error));

        return;
      }

      createMember(data.formData)
        .unwrap()
        .then(async () => {
          await refetch();
          closeModal?.();
        })
        .catch((error) => setErrors(error));
    },
    validator,
    uiSchema: {
      "ui:submitButtonOptions": {
        text: t(defaultValues ? "save" : "create"),
        disabled: isLoading || isLoadingEdit,

      },
      requiredFields: {
        "ui:field": "requiredFieldsCustom",
      },
      firstName: {
        "ui:widget": "text",
        "ui:placeholder": "John",
        "ui:width": "50%",
      },
      lastName: {
        "ui:widget": "text",
        "ui:placeholder": "Smith",
        "ui:width": "50%",
      },
      email: {
        "ui:widget": "email",
        "ui:placeholder": "johndoe@example.com",
        "ui:width": "50%",
      },
      role: {
        "ui:widget": "select",
        "ui:width": "50%",
      },
      client: {
        "ui:widget": "select",
        "ui:width": "50%",
      },
      enabled: {
        "ui:widget": "radio",
        "ui:options": {
          inline: true,
        },
      },
      tempPassword: {
        "ui:widget": (props: any) => (
          <PasswordGenerateWidget {...props} customUpdate={props.onChange} />
        ),
        "ui:placeholder": "User will have to make a new password on sign-in",
        "ui:width": "50%",
        "ui:disabled": !!defaultValues?.id,
        "ui:hidden": defaultValues ? true : false,
      },
    },
    fields: {
      requiredFieldsCustom: () => <p style={{ color: "#E51C00", textAlign: "center" }}>{t("pages.members.requiredFields")}</p>,
    },
    schema: {
      type: "object",
      required: ["firstName", "lastName", "email", "role", "client", "tempPassword"],
      properties: {
        requiredFields: {
          type: "string",
          title: " "
        },
        firstName: {
          type: "string",
          title: t("formInviteMember.name"),
          default: defaultValues?.firstName,
          maxLength: 100,
        },
        client: {
          type: "string",
          title: t("formInviteMember.client"),
          default: defaultValues?.groups[0],
          enum: clients
            ? clients.data.map((client) => slugify(client.name))
            : [],
        },
        lastName: {
          type: "string",
          title: t("formInviteMember.surname"),
          default: defaultValues?.lastName,
          maxLength: 100,
        },
        role: {
          type: "string",
          title: t("formInviteMember.role"),
          default: defaultValues?.role,
          enum: [
            "client-operator",
            "client-manager",
            "customer-operator",
            "key-account-manager",
          ],
        },
        email: {
          type: "string",
          title: t("formInviteMember.email"),
          default: defaultValues?.email,
          maxLength: 50,
        },
        tempPassword: {
          type: "string",
          title: t("formInviteMember.tempPassword"),
        },
        enabled: {
          type: "boolean",
          title: t("formInviteMember.enableUser"),
          default: defaultValues?.enabled,
          enum: [true, false],
        },

      },
    },
  };

  return (
    <Form id="new-member-form" {...formData} asyncErrors={errors}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          className={styles.formButton}
          intent="primary-light"
          text={t("cancel")}
          onClick={closeModal}
        />

        <Button
          className={styles.formButton}
          intent="black"
          icon={{ name: "add", intent: "white", size: 12 }}
          type="submit"
          text={defaultValues?.id ? t("formInviteMember.edit") : t("formInviteMember.create")}
        />
      </div>
    </Form>);
}
