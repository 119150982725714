/**
 * Represents a wrapper for interacting with the localStorage API with version control
 */
export class LocalStorage {
  private storageKeyPrefix: string;
  private currentVersion: number;
  private storageVersionKey: string;

  constructor(storageKeyPrefix = "app", currentVersion = 1) {
    this.storageKeyPrefix = storageKeyPrefix;
    this.currentVersion = currentVersion;
    this.storageVersionKey = `${this.storageKeyPrefix}_version`;

    this.checkAndClearOldData();
  }

  private checkAndClearOldData(): void {
    const storedVersion = localStorage.getItem(this.storageVersionKey);
    if (storedVersion && storedVersion !== String(this.currentVersion)) {
      // Versions don't match - clear old data
      this.clearAll();
    }

    // Store the current version
    localStorage.setItem(this.storageVersionKey, String(this.currentVersion));
  }

  setItem(key: string, value: any): void {
    localStorage.setItem(
      `${this.storageKeyPrefix}_${key}`,
      JSON.stringify(value),
    );
  }

  getItem<T>(key: string): T | null {
    const rawValue = localStorage.getItem(`${this.storageKeyPrefix}_${key}`);
    if (!rawValue) {
      return null;
    }

    try {
      return JSON.parse(rawValue) as T;
    } catch (e) {
      return null;
    }
  }

  removeItem(key: string): void {
    localStorage.removeItem(`${this.storageKeyPrefix}_${key}`);
  }

  clearAll(): void {
    for (let i = localStorage.length - 1; i >= 0; i--) {
      const key = localStorage.key(i);
      if (key && key.startsWith(this.storageKeyPrefix)) {
        localStorage.removeItem(key);
      }
    }
  }
}

export const appStorage = new LocalStorage("bop_app", 1);
export const widgetsStorage = new LocalStorage("widgets", 1);
