import { ChangeEvent } from "react";

import { WidgetProps } from "@rjsf/utils";

import { classNames } from "lib/classNames";

import styles from "./TextareaWidget.module.scss";

export function TextareaWidget(props: WidgetProps) {
  const {
    id,
    placeholder,
    disabled,
    onChange,
    onChangeOverride,
    onBlur,
    onFocus,
    value,
    rawErrors,
  } = props;

  const onTextChange = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.(value === "" ? undefined : value);
  };
  const onTextBlur = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement>) => onBlur?.(id, value);
  const onTextFocus = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement>) => onFocus?.(id, value);

  return (
    <textarea
      id={id}
      name={props.name}
      className={classNames(styles.textarea, {
        [styles.textareaError]:
          rawErrors && rawErrors?.length > 0 ? true : false,
      })}
      disabled={disabled}
      placeholder={placeholder}
      defaultValue={value}
      onChange={onChangeOverride || onTextChange}
      onBlur={onTextBlur}
      onFocus={onTextFocus}
    />
  );
}
