import { CoreRow, createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { Button } from "components/Button";
import { ContextMenu } from "components/ContextMenu";
import { DashboardHeader } from "components/DashboardHeader";
import { Pill } from "components/Pill";
import { Table } from "components/Table";

import { readableId } from "lib/helpers";
import { useHead } from "lib/hooks/useHead";
import {
  useDeleteClientMutation,
  useGetClientsQuery,
} from "lib/slices/clientServiceAPISlice";

import styles from "./PageSAClients.module.scss";

const columnHelper = createColumnHelper<Client>();

export function PageSAClients() {
  const { t } = useTranslation();
  const { data, isLoading } = useGetClientsQuery();
  const [softDelete] = useDeleteClientMutation();
  const navigate = useNavigate();
  useHead(t("pages.superAdmin.clients.header"));

  const items = data && data.data;

  const deleteClient = (row: CoreRow<Client>["original"]) => {
    softDelete(row.id);
  };

  const columns = [
    columnHelper.accessor("name", {
      header: t("pages.superAdmin.clients.tableColumns.clientName"),
      cell: (info) => (
        <div className={styles.columnMultiple}>
          <div className={styles.clientLogo}>
            <img src={info.row.original.logo_uri} />
          </div>
          <p>{info.row.original.name}</p>
        </div>
      ),
      meta: {
        headerIcon: "usermultiplegroup",
      },
    }),
    columnHelper.accessor("id", {
      header: t("pages.superAdmin.clients.tableColumns.id"),
      cell: (info) => (
        <Link to={`/sa/clients/${info.getValue()}`}>
          <Pill text={readableId(info.getValue())} intent="Link" />
        </Link>
      ),
      meta: {
        headerIcon: "usermultiplegroup",
      },
    }),
    columnHelper.accessor("legalEntity", {
      header: t("pages.superAdmin.clients.tableColumns.legalEntity"),
      meta: {
        headerIcon: "bank",
      },
    }),
    columnHelper.accessor("orgNr", {
      header: t("pages.superAdmin.clients.tableColumns.orgNr"),
      meta: {
        headerIcon: "mail",
      },
    }),
    columnHelper.accessor("address1", {
      id: "address",
      header: t("pages.superAdmin.clients.tableColumns.address"),
      meta: {
        headerIcon: "home1",
      },
    }),
    columnHelper.accessor("visibleContactAddress", {
      header: t("pages.superAdmin.clients.tableColumns.email"),
      meta: {
        headerIcon: "mail",
      },
    }),
    columnHelper.accessor("website", {
      header: t("pages.superAdmin.clients.tableColumns.website"),
      cell: (info) => (
        <a href={info.getValue()}>
          <Pill text={info.getValue()} intent="Link" />
        </a>
      ),
      meta: {
        headerIcon: "Uweb",
      },
    }),
    columnHelper.display({
      id: "actions",
      cell: (info) => (
        <ContextMenu
          menuItems={[
            {
              icon: "informationcircle",
              label: "View More & Edit Data",
              onClick: () => navigate(`/sa/clients/${info.row.original.id}`),
            },
            {
              icon: "recyclebin",
              label: "Delete Client",
              onClick: () => deleteClient(info.row.original),
            },
          ]}
        >
          <Button icon={{ name: "horizontalmenucircle" }} intent="clear" />
        </ContextMenu>
      ),
    }),
  ];

  return (
    <main className={styles.main}>
      <DashboardHeader
        title={t("pages.superAdmin.clients.header")}
        subHeader={"Manage your clients from here."}
        badge={`${items?.length} clients`}
      />

      <Table
        data={items}
        columns={columns}
        header={true}
        isLoading={isLoading}
        headerCta={
          <Button
            text={t("pages.superAdmin.clients.newClient")}
            icon={{ name: "addcircle" }}
            onClick={() => navigate("/sa/clients/new")}
          />
        }
      />
    </main>
  );
}
