import { ButtonHTMLAttributes, CSSProperties, ReactNode } from "react";

import { Icon, IconProps } from "components/Icon";

import { classNames } from "lib/classNames";

import styles from "./Button.module.scss";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  onClick?: () => void;
  icon?: IconProps;
  intent?:
    | "primary"
    | "secondary"
    | "danger"
    | "success"
    | "default"
    | "purple-light"
    | "clear"
    | "black"
    | "primary-light";
  outline?: boolean;
  slim?: boolean;
  fill?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  type?: "button" | "submit" | "reset";
  children?: ReactNode;
  style?: CSSProperties;
}

export function Button({
  text,
  onClick,
  intent = "default",
  outline = false,
  icon,
  slim,
  type = "button",
  fill,
  disabled = false,
  children,
  hasError = false,
  ...props
}: ButtonProps) {
  const withOutline = outline ? "-outline" : "";

  return (
    <button
      {...props}
      className={classNames(
        // @ts-expect-error TODO: Fix me - complaining about outline
        styles[`button-${intent}${withOutline}`],
        props?.className ?? "",
        { [styles.buttonError]: hasError },
      )}
      style={{
        height: slim ? 40 : 44,
        width: fill ? "100%" : undefined,
        minWidth: !text ? 0 : undefined,
        ...props.style,
      }}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {icon && <Icon {...icon} />}
      {text}
      {children}
    </button>
  );
}
