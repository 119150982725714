import axios from "axios";
import { FILE_SERVICE } from "config/common";

class FileService {
  async upload(file: File, clientSlug: string, type: "logo" | "banner") {
    const data = new FormData();
    data.append("file", file, `${clientSlug}-${type}.png`);

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${FILE_SERVICE}/upload`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export default new FileService();
