import { ReactNode, useState } from "react";

import { Button } from "components/Button";
import { Icon } from "components/Icon";

import { classNames } from "lib/classNames";

import styles from "./Accordion.module.scss";

type Props = {
  title: string;
  children: ReactNode;
};

export function Accordion({ title, children }: Props) {
  const [opened, setOpened] = useState(false);

  return (
    <div className={styles.accordionContainer}>
      <Button
        intent="clear"
        onClick={() => setOpened(!opened)}
        className={classNames(styles.button, {
          [styles.buttonActive]: opened,
        })}
      >
        <p>{title}</p>
        <Icon name={opened ? "subtract" : "addcircle"} />
      </Button>

      <div
        className={classNames(styles.accordionContent, {
          [styles.accordionContentActive]: opened,
        })}
      >
        <div>{children}</div>
      </div>
    </div>
  );
}
