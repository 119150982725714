import { useRef } from "react";

import { Icon } from "components/Icon";

import styles from "./Badge.module.scss";

type Props = {
  status: string;
  statusText?: string;
};

export function Badge({ status, statusText }: Props) {
  const divRef = useRef<HTMLDivElement>(null);

  if (divRef.current) {
    if (
      divRef.current.offsetWidth <
      (divRef.current.querySelector("span") as HTMLSpanElement).offsetWidth
    ) {
      divRef.current.classList.add(styles.autoWidth);
    }
  }

  if (status === "inactive") {
    return (
      <div ref={divRef} className={styles.badgeInactive}>
        <Icon name="delete1" size={12} color="#B42318" />
        <span> {statusText || status}</span>
      </div>
    );
  }

  if (status === "active") {
    return (
      <div ref={divRef} className={styles.badge}>
        <Icon name="check" size={12} color="#027A48" />
        <span>{statusText || status}</span>
      </div>
    );
  }

  if (status === "invited") {
    return (
      <div ref={divRef} className={styles.badgePending}>
        <Icon name="mailsendenvelope" size={12} color="#b48d18" />
        <span> {statusText || status}</span>
      </div>
    );
  }

  return (
    <div ref={divRef} className={styles.badgePrimary}>
      <span>{status}</span>
    </div>
  );
}
