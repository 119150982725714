import { Table as ReactTable } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import { Icon } from "components/Icon";

import styles from "./Table.module.scss";

interface PageAction {
  action: "goto" | "skip-forward" | "skip-backward";
  number: number;
}

interface TablePaginationProps<TData> {
  table: ReactTable<TData>;
  pagesToRender: PageAction[];
  children?: React.ReactNode;
}

export function TablePagination<TData>({
  table,
  pagesToRender,
  children,
}: TablePaginationProps<TData>) {
  const { t } = useTranslation();

  return (
    <div className={styles.paginationContainer}>
      {children}
      <div>
        <button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
          style={{ marginRight: "auto" }}
        >
          <Icon name="arrowup" size={20} />
          <span>{t("pages.customersCases.prevButtonPagination")}</span>
        </button>
        {pagesToRender.map((page, i) => {
          const handleClick = () => {
            if (page.action === "goto") {
              table.setPageIndex(page.number - 1);
            } else if (page.action === "skip-forward") {
              table.setPageIndex(
                Math.min(table.getPageCount() - 1, page.number - 1),
              );
            } else if (page.action === "skip-backward") {
              table.setPageIndex(Math.max(0, page.number - 1));
            }
          };
          return (
            <button
              key={i}
              onClick={handleClick}
              style={{
                backgroundColor:
                  table.getState().pagination.pageIndex + 1 === page.number
                    ? "#EBEBEB"
                    : undefined,
              }}
            >
              {page.action.startsWith("skip") ? "..." : page.number}
            </button>
          );
        })}
        <button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
          style={{ marginLeft: "auto" }}
        >
          <span>{t("pages.customersCases.nextButtonPagination")}</span>
          <Icon name="arrowup" size={20} />
        </button>
      </div>
    </div>
  );
}
