import { ArrayFieldTemplateProps } from "@rjsf/utils";
import { icons } from "assets/icons";

import { Button } from "components/Button";
import { Icon } from "components/Icon";

import { classNames } from "lib/classNames";

import styles from "./ArrayFieldTemplate.module.scss";

interface Props extends ArrayFieldTemplateProps {
  uiSchema: {
    title: string;
    iconName: keyof typeof icons;
    "ui:type": string;
  };
}

export function ArrayFieldTemplate(props: Props) {
  const { uiSchema } = props;
  const { title, iconName } = uiSchema;
  const clearTheme = uiSchema["ui:type"] === "clear";

  return (
    <div
      className={classNames(styles.formCard, {
        [styles.formCardClear]: clearTheme,
      })}
    >
      <div className={styles.header}>
        {iconName && (
          <div>
            <Icon name={iconName} />
            <p>{title}</p>
          </div>
        )}
        <Button onClick={props.onAddClick} intent="clear">
          <div className={styles.plusIcon}>
            <Icon name="add" size={18} intent="white" />
          </div>
        </Button>
      </div>
      {props.items?.map((element: any, index: number) => {
        const deleteItem = element.onDropIndexClick(element.index);
        return (
          <div
            className={styles.form}
            key={index}
            style={{
              borderTop:
                index !== 0 && clearTheme
                  ? "1px solid var(--color-gray-light)"
                  : "none",
            }}
          >
            {element.children}
            {index !== 0 && (
              <Button
                intent="clear"
                icon={{ name: "deletekeyboard", intent: "neutral", size: 24 }}
                className={styles.deleteBtn}
                onClick={deleteItem}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}
