interface Element {
  id: number;
  [key: string]: any;
}

export function resizePanel(
  _rows: Array<Array<Element>>,
  fromId: number | string,
  toId: number | string | null,
  alignLeft = false,
  newRowIndex: number | null = null,
): Array<Array<Element>> {
  const rows = [..._rows];
  let fromIndex: number | undefined;
  let toIndex: number | undefined;
  let fromParentIndex: number | undefined;
  let toParentIndex: number | undefined;
  const maxRows = 2;

  if (fromId === toId) {
    return rows;
  }

  // Find indexes of elements
  rows.forEach((columns, rowIndex) => {
    columns.forEach((c, index) => {
      if (c.id === fromId) {
        fromIndex = index;
        fromParentIndex = rowIndex;
      } else if (c.id === toId) {
        toIndex = index;
        toParentIndex = rowIndex;
      }
    });
  });

  // New row from element
  if (
    newRowIndex !== null &&
    fromParentIndex !== undefined &&
    fromIndex !== undefined
  ) {
    const oldElement = rows[fromParentIndex][fromIndex];

    // Delete old element
    rows[fromParentIndex].splice(fromIndex, 1);

    // Insert into new position
    rows.splice(newRowIndex, 0, [oldElement]);
  } else if (
    fromParentIndex !== undefined &&
    fromIndex !== undefined &&
    toParentIndex !== undefined &&
    toIndex !== undefined
  ) {
    // Swap elements if in same inner array
    if (fromParentIndex === toParentIndex) {
      [rows[fromParentIndex][fromIndex], rows[toParentIndex][toIndex]] = [
        rows[toParentIndex][toIndex],
        rows[fromParentIndex][fromIndex],
      ];
      // Swap elements if maxRow limit is hit
    } else if (rows[toParentIndex].length >= maxRows) {
      [rows[fromParentIndex][fromIndex], rows[toParentIndex][toIndex]] = [
        rows[toParentIndex][toIndex],
        rows[fromParentIndex][fromIndex],
      ];
      return rows.filter((row) => row.length > 0);
    } else {
      // Insert into new position
      alignLeft
        ? rows[toParentIndex].unshift(_rows[fromParentIndex][fromIndex])
        : rows[toParentIndex].push(_rows[fromParentIndex][fromIndex]);

      // Delete from original position
      rows[fromParentIndex].splice(fromIndex, 1);
    }
  }

  // Remove empty arrays
  return rows.filter((row) => row.length > 0);
}
