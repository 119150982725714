import { CSSProperties, createElement } from "react";

import { icons } from "assets/icons";

import { classNames } from "lib/classNames";

import styles from "./Icon.module.scss";

export type IconProps = {
  name: keyof typeof icons;
  className?: string;
  intent?:
    | "primary"
    | "secondary"
    | "blue"
    | "blue-light"
    | "neutral"
    | "neutral-light"
    | "invalid"
    | "valid"
    | "black"
    | "white"
    | "coral"
    | "pink"
    | "gray-light"
    | "purple-light";
  color?: string;
  size?: number;
  style?: CSSProperties;
  onClick?: (e: Event) => void;
};

export function Icon({
  name,
  className,
  intent = "black",
  color,
  size = 24,
  style,
  onClick,
}: IconProps) {
  return createElement(icons[name], {
    className: classNames(styles.icon, className),
    style: {
      "--icon-color": color ? color : `var(--color-${intent})`,
      "--icon-size": `${size}px`,
      ...style,
    },
    onClick,
  });
}
