import { ChangeEvent } from "react";

import { BaseInputTemplateProps } from "@rjsf/utils";
import { RInioInput } from "ui-core";

/**
 * This component extends the BaseInputTemplate from rjsf.
 * https://rjsf-team.github.io/react-jsonschema-form/docs/advanced-customization/custom-templates#baseinputtemplate
 */
export function NumberInputTemplate(props: BaseInputTemplateProps) {
  const {
    id,
    placeholder,
    onChange,
    onChangeOverride,
    onBlur,
    onFocus,
    value,
    uiSchema,
    schema,
    disabled,
    options
  } = props;

  const onTextChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    onChange?.(value === "" ? undefined : value);
  };
  const onTextBlur = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    onBlur?.(id, value);
  const onTextFocus = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    onFocus?.(id, value);

  return (
    <RInioInput
      id={id}
      name={props.name}
      type="number"
      disabled={schema?.readOnly || disabled ? true : false}
      placeholder={placeholder}
      value={value ?? ""}
      onChange={onChangeOverride || onTextChange}
      autoComplete={uiSchema?.["ui:autocomplete"]}
      onBlur={onTextBlur}
      onFocus={onTextFocus}
      editable={uiSchema?.["ui:editable"]}
      readonly={uiSchema?.["ui:editable"] || schema?.readOnly}
      maxLength={schema?.maxLength}
      minLength={schema?.minLength}
      min={options?.min}
      max={options?.max}
      link={
        uiSchema?.["ui:link"]
          ? {
              href: uiSchema["ui:link"].href,
              title: uiSchema["ui:link"].text,
            }
          : undefined
      }
    />
  );
}
