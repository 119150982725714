import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { widgetsStorage } from "lib/localStorage";

import { resizePanel } from "./resizePanel";

type SelectedWidgets = {
  dashboardData: number[];
  collectionsData: number[];
  inboundData: number[];
  outboundSuccessData: number[];
  outboundBounceData: number[];
  outboundPortfolioData: number[];
};

const existingWidgets: Widgets = {
  dashboardData: [],
  collectionsData: [],
  inboundData: [],
  outboundSuccessData: [],
  outboundBounceData: [],
  outboundPortfolioData: [],
};

const selectedWidgets: SelectedWidgets = {
  dashboardData: [],
  collectionsData: [],
  inboundData: [],
  outboundSuccessData: [],
  outboundBounceData: [],
  outboundPortfolioData: [],
};

export const widgetPanelSlice = createSlice({
  name: "widgetPanel",
  initialState: {
    entities: existingWidgets,
    selectedWidgets: selectedWidgets,
    deletedWidgets: (widgetsStorage.getItem("deleted") ??
      existingWidgets) as Widgets,
  },
  reducers: {
    setData: (
      state,
      action: PayloadAction<{ storageKey: keyof Widgets; data: any }>,
    ) => {
      const { storageKey, data } = action.payload;
      state.entities[storageKey] = data;
    },
    updateDataById: (
      state,
      action: PayloadAction<{
        id: number;
        storageKey: keyof Widgets;
        data: any;
      }>,
    ) => {
      const { id, data, storageKey } = action.payload;
      const updatedWidgetData = state.entities[storageKey].map((widget) => {
        return widget.map((w) => {
          if (w.id === id) {
            return { ...w, ...data };
          }
          return w;
        });
      });

      widgetsStorage.setItem(storageKey, updatedWidgetData);
      state.entities[storageKey] = updatedWidgetData;
    },
    reorderData: (
      state,
      action: PayloadAction<{
        storageKey: keyof Widgets;
        dragId: number | string;
        dropAreaId: number | string;
      }>,
    ) => {
      const { dragId, dropAreaId, storageKey } = action.payload;
      const orderedData = resizePanel(
        state.entities[storageKey],
        dragId,
        dropAreaId,
      );

      widgetsStorage.setItem(storageKey, orderedData);
      state.entities[storageKey] = orderedData as Graph[][];
    },
    createNewRow: (
      state,
      action: PayloadAction<{
        storageKey: keyof Widgets;
        dragId: number | string;
        dropAreaId: number;
      }>,
    ) => {
      const { dragId, dropAreaId, storageKey } = action.payload;
      const orderedData = resizePanel(
        state.entities[storageKey],
        dragId,
        null,
        false,
        dropAreaId,
      );

      widgetsStorage.setItem(storageKey, orderedData);
      state.entities[storageKey] = orderedData as Graph[][];
    },
    updateSelectedWidgets: (
      state,
      action: PayloadAction<{
        id: number;
        storageKey: keyof Widgets;
      }>,
    ) => {
      const { storageKey, id } = action.payload;

      if (state.selectedWidgets[storageKey]?.includes(id)) {
        state.selectedWidgets[storageKey] = state.selectedWidgets[
          storageKey
        ].filter((widgetId) => widgetId !== id);
      } else {
        state.selectedWidgets[storageKey] = [
          ...(state.selectedWidgets[storageKey] || []),
          id,
        ];
      }
    },
    deleteSelectedWidgets: (
      state,
      action: PayloadAction<{
        storageKey: keyof Widgets;
      }>,
    ) => {
      const { storageKey } = action.payload;
      const updatedWidgets = state.entities[storageKey].map((row) => {
        return row.map((column) => {
          if (state.selectedWidgets[storageKey]?.includes(column.id)) {
            // Add 'hidden' property to deleted widget
            const deletedWidget = { ...column, hidden: true };
            // Update deletedWidgets state
            // @ts-expect-error type error
            state.deletedWidgets[storageKey].push(deletedWidget);
            // Update deletedWidgets in localStorage
            widgetsStorage.setItem("deleted", state.deletedWidgets);
            return deletedWidget;
          } else {
            return column;
          }
        });
      });

      widgetsStorage.setItem(storageKey, updatedWidgets);
      state.entities[storageKey] = updatedWidgets;
      state.selectedWidgets[storageKey] = [];
    },
    restoreDeletedWidget: (
      state,
      action: PayloadAction<{
        widgetId: number;
        storageKey: keyof Widgets;
      }>,
    ) => {
      const { storageKey, widgetId } = action.payload;

      const updatedWidgets = state.entities[storageKey].map((row) => {
        return row.map((column) => {
          if (column.id === widgetId) {
            return { ...column, hidden: false };
          } else {
            return column;
          }
        });
      });

      state.deletedWidgets[storageKey] = state.deletedWidgets[
        storageKey
        // @ts-expect-error type error
      ].filter((widget) => widget.id !== widgetId);
      widgetsStorage.setItem("deleted", state.deletedWidgets);
      widgetsStorage.setItem(storageKey, updatedWidgets);
      state.entities[storageKey] = updatedWidgets;
    },
    resetGraphs: (
      state,
      action: PayloadAction<{
        originalData: Graph[][];
        storageKey: keyof Widgets;
      }>,
    ) => {
      const { storageKey, originalData } = action.payload;
      widgetsStorage.setItem(storageKey, originalData);
      state.deletedWidgets[storageKey] = [];
      widgetsStorage.setItem("deleted", state.deletedWidgets);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setData,
  updateDataById,
  updateSelectedWidgets,
  deleteSelectedWidgets,
  restoreDeletedWidget,
  resetGraphs,
  reorderData,
  createNewRow,
} = widgetPanelSlice.actions;

export default widgetPanelSlice.reducer;

export const selectWidgetById = (
  state: { entities: { [x: string]: any[] } },
  key: string,
  widgetId: number,
) => {
  return state.entities[key]
    ?.flat()
    .find((widget: { id: any }) => widget.id === widgetId);
};
