import { icons } from "assets/icons";

import { Icon } from "components/Icon";

import styles from "./DropdownUser.module.scss";

type Props = {
  title: string;
  icon: keyof typeof icons;
  onClick?: () => void;
  textRight?: boolean;
};

export function DropdownUserRow({ title, icon, onClick, textRight }: Props) {
  return (
    <button
      className={styles.dropdownRow}
      style={{ cursor: onClick ? "pointer" : "auto" }}
      onClick={onClick}
    >
      <Icon name={icon} size={16} />
      <p>{title}</p>
      {textRight && <p className={styles.dropdownRowRight}>{textRight}</p>}
    </button>
  );
}
