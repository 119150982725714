import { ColumnFiltersState, Updater } from "@tanstack/react-table";

import { encode as encodeBase64, decode as decodeBase64 } from "js-base64";

const queryParam = "f";

export function resolveFilters(
  filters: Updater<ColumnFiltersState>,
  currentFilters: ColumnFiltersState,
): ColumnFiltersState {
  if (typeof filters === "function") {
    return filters(currentFilters);
  }
  return filters;
}

export function encodeFilters(filters: ColumnFiltersState) {
  const serializedFilters = JSON.stringify(filters);
  return encodeBase64(serializedFilters);
}

export function decodeFilters(encodedFilters: string) {
  const decodedFilters = decodeBase64(encodedFilters);
  return JSON.parse(decodedFilters);
}

export function updateUrlWithFilters(filters: ColumnFiltersState) {
  const encodedFilters = encodeFilters(filters);
  const url = new URL(window.location.href);
  url.searchParams.set(queryParam, encodedFilters);
  window.history.replaceState({}, "", url.toString());
}

export function parseFiltersFromUrl() {
  const url = new URL(window.location.href);
  const encodedFilters = url.searchParams.get(queryParam);

  if (!encodedFilters) return [];

  return decodeFilters(encodedFilters);
}
