import { useTranslation } from "react-i18next";
import { RedocStandalone } from "redoc";
import { HOST_CLIENT_SERVICE } from "config/common";

import { useHead } from "lib/hooks/useHead";

export function Docs() {
  const { t } = useTranslation();
  useHead(t("docs"));

  const specUrl = `${HOST_CLIENT_SERVICE}/api-docs.yaml`;

  return (
    <div>
      <RedocStandalone specUrl={specUrl}/>
    </div>
  );
}
