import { useRef, useEffect, ReactNode, useCallback } from "react";

import { Icon } from "components/Icon";

import { useOutsideClick } from "lib/hooks/useOutsideClick";

import styles from "./Modal.module.scss";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  header?: string;
  children: ReactNode;
};

export function Modal({ isOpen, onClose, header, children }: Props) {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useOutsideClick(contentRef, () => {
    onClose();
  });

  const handleKeydownEvent = useCallback((e: KeyboardEvent) => {
    if (e.key === "Escape") {
      onClose();
    }
  }, []);

  useEffect(() => {
    if (dialogRef.current?.open && !isOpen) {
      dialogRef.current?.close();
    } else if (!dialogRef.current?.open && isOpen) {
      dialogRef.current?.showModal();
    }

    window.addEventListener("keydown", handleKeydownEvent);

    return () => window.removeEventListener("keydown", handleKeydownEvent);
  }, [isOpen]);

  return (
    isOpen && (
      <dialog ref={dialogRef} className={styles.dialog}>
        <main ref={contentRef}>
          <header className={styles.modalHeader}>
            {header && <h2>{header}</h2>}
            <button onClick={onClose}>
              <Icon name="delete1" size={14} />
            </button>
          </header>
          {children}
        </main>
      </dialog>
    )
  );
}
