import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { User } from "react-oidc-context";
import { useSelector } from "react-redux";

import { icons } from "assets/icons";

import { Button } from "components/Button";
import { Icon } from "components/Icon";

import { classNames } from "lib/classNames";
import useMediaQuery from "lib/hooks/useMediaQuery";

import breakpoints from "styles/mixins.scss";

import styles from "./Sidebar.module.scss";
import { SidebarLink } from "./SidebarLink";

interface Route {
  icon: string;
  textKey: string;
  href?: string;
  subMenu?: {
    textKey: string;
    href: string;
  }[];
  authorised?: string[];
}

interface SidebarProps {
  config: Route[];
  dataUser: User;
  logOutHandler: () => void;
}

export const Sidebar = ({
  config = [],
  dataUser,
  logOutHandler,
}: SidebarProps) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop})`);
  const theme = useSelector((state: any) => state.theme.theme);
  const [isOpen, setIsOpen] = useState(isDesktop);

  useEffect(() => {
    setIsOpen(isDesktop);
  }, [isDesktop]);

  return (
    <>
      <div className={styles.filler} />
      <nav
        className={classNames(styles.sidebarContainer, {
          [styles.sidebarContainerOpen]: isOpen,
        })}
      >
        <div
          className={classNames(styles.logoContainer, {
            [styles.logoContainerOpen]: isOpen,
          })}
        >
          <img src={`/${theme}/logo` + ".svg"} alt="logo" />
          <button onClick={() => setIsOpen(!isOpen)}>
            <Icon name={isOpen ? "moveleft" : "moveright"} />
          </button>
        </div>
        <div className={styles.linksContainer}>
          {config?.map((route, i) => {
            if (
              route.authorised &&
              !route.authorised.some((role) =>
                dataUser.profile.roles.includes(role),
              )
            ) {
              return null;
            }

            return (
              <SidebarLink
                key={i}
                icon={route.icon as keyof typeof icons}
                text={t(route.textKey)}
                subMenu={route.subMenu}
                href={route.href}
                sidebarOpen={isOpen}
                openSidebar={setIsOpen}
              />
            );
          })}
        </div>
        <div
          className={classNames(styles.footer, {
            [styles.footerOpen]: isOpen,
          })}
        >
          {dataUser && (
            <div className={styles.footerUser}>
              <p className={styles.footerUserName}>{dataUser?.profile.name}</p>
              <p className={styles.footerUserEmail}>
                {dataUser?.profile.email}
              </p>
            </div>
          )}
          <Button
            text={t("logout")}
            icon={{ name: "streamlineCoreDownload" }}
            fill
            slim
            onClick={logOutHandler}
            className={styles.logoutBtn}
          />
        </div>
      </nav>
    </>
  );
};
