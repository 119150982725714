import { useMemo } from "react";

/**
 * Generates the pagination controls.
 *
 * This hook calculates the range of page numbers to display based on the current page,
 * total number of pages, maxPageButtons, and skipPages. It ensures that navigation
 * buttons (first, last, skip forward, skip backward) are included.
 *
 * @param {Object} table - The Tanstack Table instance to paginate.
 * @param {number} maxPageButtons - The maximum number of page buttons to display (default: 5).
 * @param {number} skipPages - The number of pages to skip when using skip buttons (default: 4).
 *
 * @returns {Array} An array of page objects. Each object represents a page button
 * and contains a 'number' key indicating the page number, and an 'action' key
 * indicating the type of action ('goto', 'skip-forward', 'skip-backward').
 */
export const usePagination = (
  table: any,
  maxPageButtons = 5,
  skipPages = 4,
): Array<any> => {
  return useMemo(() => {
    const pageCount = table.getPageCount();
    const currentPage = table.getState().pagination.pageIndex + 1;
    const pages = [];

    // Determine the range of page numbers to display
    let startPage = Math.max(2, currentPage - Math.floor(maxPageButtons / 2));
    const endPage = Math.min(pageCount - 1, startPage + maxPageButtons - 1);

    if (endPage - startPage < maxPageButtons - 1) {
      startPage = Math.max(2, endPage - maxPageButtons + 1);
    }

    // Add the first page
    pages.push({ number: 1, action: "goto" });

    // Add backward skip if needed
    if (startPage > 2) {
      pages.push({ number: currentPage - skipPages, action: "skip-backward" });
    }

    // Generate the middle range of page numbers
    for (let i = startPage; i <= endPage; i++) {
      pages.push({ number: i, action: "goto" });
    }

    // Add forward skip if needed
    if (endPage < pageCount - 1) {
      pages.push({ number: currentPage + skipPages, action: "skip-forward" });
    }

    // Add the last page
    if (pageCount > 1) {
      pages.push({ number: pageCount, action: "goto" });
    }

    return pages;
  }, [table.getState(), maxPageButtons, skipPages]);
};
