import validator from "@rjsf/validator-ajv8";

import { FormClaim } from "components/FormClaim";

import i18n from "utils/i18n";

const entities = ["Peter Parker", "Brad Pitt", "James Bond"];

export const stepConfigurations = (
  handleOnSubmit: {
    (step: number, newFormData: object): void;
    (arg0: number, arg1: any): any;
  },
  formData: { entity?: any; files?: any },
) => [
  {
    title: i18n.t("newClaim.createEntity"),
    uiSchema: {
      "ui:submitButtonOptions": {
        parentProps: {
          hidden: true,
        },
      },
      entity: {
        "ui:widget": "select",
        "ui:placeholder": "choose or search for an entity name or ID",
        icon: { name: "usercirclesingle" },
      },
    },
    schema: {
      type: "object",
      required: ["entity"],
      properties: {
        entity: {
          type: "string",
          title: i18n.t("newClaim.chooseEntity"),
          enum: entities,
          default: formData.entity,
        },
      },
    },
    validator: validator,
    onSubmit: (data: { formData: any }) => handleOnSubmit(0, data.formData),
  },
  {
    title: i18n.t("newClaim.createClaim"),
    uiSchema: {
      "ui:submitButtonOptions": {
        parentProps: {
          hidden: true,
        },
      },
      files: {
        "ui:widget": "FileWidget",
      },
      claims: {
        "ui:ArrayFieldTemplate": FormClaim,
        items: {
          reference: {
            "ui:widget": "text",
            "ui:width": "50%",
          },
          value: {
            "ui:widget": "text",
            "ui:width": "50%",
          },
          contractualItem: {
            "ui:widget": "select",
            "ui:placeholder": i18n.t("newClaim.contractualItem"),
            "ui:width": "50%",
          },
          currency: {
            "ui:widget": "select",
            "ui:placeholder": i18n.t("newClaim.currency"),
            "ui:width": "50%",
          },
          occurenceDate: {
            "ui:widget": "date",
            "ui:width": "50%",
            "ui:options": {
              wide: true,
            },
          },
          dueDate: {
            "ui:widget": "date",
            "ui:width": "50%",
            "ui:options": {
              wide: true,
            },
          },
          subjectMatter: {
            "ui:widget": "textarea",
            "ui:placeholder": i18n.t("newClaim.subjectMatterPlaceholder"),
          },
          charges: {
            title: i18n.t("newClaim.charges"),
            iconName: "bagdollar",
            items: {
              reference: {
                "ui:widget": "text",
                "ui:width": "50%",
              },
              contractual: {
                "ui:widget": "select",
                "ui:width": "50%",
              },
              value: {
                "ui:widget": "text",
                "ui:width": "50%",
              },
              occurenceDate: {
                "ui:widget": "date",
                "ui:width": "50%",
              },
            },
          },
          metadata: {
            title: i18n.t("newClaim.metadata"),
            iconName: "bracket",
            items: {
              type: {
                "ui:widget": "select",
                "ui:placeholder": i18n.t("newClaim.type"),
                "ui:width": "50%",
              },
              value: {
                "ui:widget": "text",
                "ui:width": "50%",
              },
            },
          },
        },
      },
    },
    schema: {
      type: "object",
      required: ["files"],
      properties: {
        files: {
          title: i18n.t("fileWidget.uploadFiles"),
          type: "array",
          items: {
            type: "object",
            format: "data-url",
          },
          default: formData.files,
        },
        claims: {
          type: "array",
          default: [{}],
          items: {
            type: "object",
            title: "",
            minItems: 1,
            required: [
              "reference",
              "value",
              "contractualItem",
              "currency",
              "occurenceDate",
              "dueDate",
              "subjectMatter",
            ],
            properties: {
              reference: {
                type: "string",
                title: i18n.t("newClaim.reference"),
              },
              value: {
                type: "string",
                title: i18n.t("newClaim.value"),
              },
              contractualItem: {
                type: "string",
                title: i18n.t("newClaim.contractualItem"),
                enum: ["contractualItem1", "contractualItem2"],
              },
              currency: {
                type: "string",
                title: i18n.t("newClaim.currency"),
                enum: i18n.t("currencies", { returnObjects: true }),
              },
              occurenceDate: {
                type: "string",
                title: i18n.t("newClaim.occurenceDate"),
                format: "date",
              },
              dueDate: {
                type: "string",
                title: i18n.t("newClaim.dueDate"),
                format: "date",
              },
              subjectMatter: {
                type: "string",
                title: i18n.t("newClaim.subjectMatter"),
              },
              charges: {
                type: "array",
                default: [{}],
                items: {
                  type: "object",
                  title: "",
                  minItems: 1,
                  required: [
                    "reference",
                    "contractual",
                    "value",
                    "occurenceDate",
                  ],
                  properties: {
                    reference: {
                      type: "string",
                      title: i18n.t("newClaim.reference"),
                    },
                    contractual: {
                      type: "string",
                      title: i18n.t("newClaim.contractual"),
                      enum: ["option1", "option2"],
                    },
                    value: {
                      type: "string",
                      title: i18n.t("newClaim.value"),
                    },
                    occurenceDate: {
                      type: "string",
                      title: i18n.t("newClaim.occurenceDate"),
                    },
                  },
                },
              },
              metadata: {
                type: "array",
                default: [{}],
                items: {
                  type: "object",
                  title: "",
                  minItems: 1,
                  required: ["type", "value"],
                  properties: {
                    type: {
                      type: "string",
                      title: i18n.t("newClaim.type"),
                      enum: ["option1", "option2"],
                    },
                    value: {
                      type: "string",
                      title: i18n.t("newClaim.value"),
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    validator: validator,
    onSubmit: (data: { formData: any }) => handleOnSubmit(1, data.formData),
  },
];
