import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { Icon } from "components/Icon";

import styles from "./FormClaim.module.scss";

/**
 * Custom implementation of ArrayFieldTemplate for rendering forms in the NewClaim component.
 *
 * @param {Object} props - The props from the rsjf form.
 * @param {string} props.uiSchema.name - Extra field required for the name of the entity.
 * @param {string} props.uiSchema.entityId - Extra field required for the ID of the entity.
 *
 * @example
 * See newClaimSchema.js
 *
 * uiSchema: {
 *   claims: {
 *     "ui:ArrayFieldTemplate": FormClaim,
 *     items: {
 *       reference: {
 *         "ui:widget": "text",
 *         "ui:width": "50%",
 *       }
 *     }
 *   }
 * }
 */
export function FormClaim(props: any) {
  const { t } = useTranslation();
  const { uiSchema } = props;
  const { name, entityId } = uiSchema;

  return (
    <>
      <div className={styles.itemsContainer}>
        {props.items?.map((element: any, index: number) => (
          <div className={styles.entityContainer} key={index}>
            <div className={styles.entityHeader}>
              <div className={styles.iconContainer}>
                <Icon name="newfile" intent="primary" />
              </div>
              <div>
                <p>{name}</p>
                <p>{t("newClaim.givenName")}</p>
              </div>
            </div>
            <div className={styles.entityInfo}>
              <div>
                <p>{t("newClaim.entityId")}</p>
                <p>{entityId}</p>
              </div>
              <div>
                <p>{t("newClaim.fileNo")}</p>
                <p>{index + 1}</p>
              </div>
            </div>
            <div>{element.children}</div>
          </div>
        ))}
      </div>
      <div className={styles.footer}>
        <Button
          text={t("newClaim.createNewCase")}
          icon={{ name: "addcircle" }}
          onClick={props.onAddClick}
        />
        <Button
          text={t("newClaim.createClaim")}
          intent="primary"
          type="submit"
        />
      </div>
    </>
  );
}
