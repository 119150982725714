import { CoreRow, Table as ReactTable } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import { RInioButton } from "ui-core";

import { SearchBox } from "components/Searchbox";

import useMediaQuery from "lib/hooks/useMediaQuery";

import breakpoints from "styles/mixins.scss";

import styles from "./Table.module.scss";
import { TableFiltersMobile } from "./TableFilters";

interface TableTopProps<TData> {
  table: ReactTable<TData>;
  ctaBtn?: React.ReactNode;
  filters?: boolean;
  selectedRows?: CoreRow<TData>[];
  setDeleteModalOpen: (open: boolean) => void;
  deleteRows?: boolean;
  searchPlaceholder: string;
}

export function TableTop<TData>({
  table,
  ctaBtn,
  filters,
  selectedRows = [],
  setDeleteModalOpen,
  deleteRows,
  searchPlaceholder,
}: TableTopProps<TData>) {
  const { t } = useTranslation();

  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop})`);

  return (
    <div className={styles.tableTop}>
      <SearchBox
        onChange={(value) => table.setGlobalFilter(value ?? "")}
        placeholder={searchPlaceholder}
      />
      {filters && <TableFiltersMobile table={table} />}
      {(ctaBtn || deleteRows) && (
        <div className={styles.tableTopCtas}>
          {deleteRows && (
            <RInioButton
              text={
                isDesktop
                  ? `${t("pages.members.deleteUsers")} ${
                      selectedRows.length > 0 ? `(${selectedRows.length})` : ""
                    }`
                  : undefined
              }
              intent="tertiary"
              icon={isDesktop ? undefined : "trash"}
              textLeft={false}
              disabled={selectedRows.length === 0}
              onClick={() => setDeleteModalOpen(true)}
            />
          )}

          {ctaBtn}
        </div>
      )}
    </div>
  );
}
