import { getSubmitButtonOptions, SubmitButtonProps } from "@rjsf/utils";

import { Button } from "components/Button";

import styles from "./ButtonTemplates.module.scss";

interface Props extends SubmitButtonProps {
  customProps: {
    parentProps: {
      hidden: boolean;
      alignLeft: boolean;
    };
    submitText: string;
  };
}

export function SubmitButton(props: Props) {
  const { uiSchema } = props;
  const { norender, ...customProps } = getSubmitButtonOptions(uiSchema);
  const { parentProps, submitText, ...btnProps } =
    customProps as Props["customProps"];

  if (norender) {
    return null;
  }

  return (
    <div
      className={styles.submitButtonContainer}
      style={{
        display: parentProps?.hidden ? "none" : undefined,
        justifyContent: parentProps?.alignLeft ? "flex-start" : "center",
        ...parentProps,
      }}
    >
      <Button type="submit" text={submitText} {...btnProps} />
    </div>
  );
}
