import { useState } from "react";
import { useTranslation } from "react-i18next";

import { StepWizard } from "components/StepWizard";

import { StepChooseEntity } from "./Steps/StepChooseEntity";
import { StepCreateClaim } from "./Steps/StepCreateClaim";
import { StepDone } from "./Steps/StepDone";
import styles from "./WizardNewClaim.module.scss";
import { stepConfigurations } from "./newClaimSchema";

type Props = {
  onCloseModal: () => void;
};

export function WizardNewClaim({ onCloseModal }: Props) {
  const [formData, setFormData] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const { t } = useTranslation();

  const handleOnSubmit = (step: number, newFormData: object) => {
    setFormData((prevData) => ({ ...prevData, ...newFormData }));
    setActiveStep(step + 1);
  };

  const resetForm = () => {
    setFormData({});
    setActiveStep(0);
  };

  const steps = stepConfigurations(handleOnSubmit, formData).map(
    (stepConfig, index) => {
      switch (index) {
        case 0:
          return {
            title: stepConfig.title,
            element: <StepChooseEntity stepConfig={stepConfig} />,
          };
        case 1:
          return {
            title: stepConfig.title,
            element: (
              <StepCreateClaim
                stepConfig={stepConfig}
                previousStepData={formData}
              />
            ),
          };
        default:
          break;
      }
    },
  );

  steps.push({
    title: t("newClaim.allDone"),
    element: (
      <StepDone
        formData={formData}
        resetForm={resetForm}
        onCloseModal={onCloseModal}
      />
    ),
  });

  return (
    <div className={styles.wizardContainer}>
      <StepWizard
        activeStep={activeStep}
        onStepClick={(step) =>
          step <= activeStep ? setActiveStep(step) : undefined
        }
        steps={steps}
      />
    </div>
  );
}
