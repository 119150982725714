import { classNames } from "lib/classNames";

import styles from "./StepWizard.module.scss";

type Props = {
  step: number;
  title: string;
  active: boolean;
  onClick: (step: number) => void;
};

export function Step({ step, title, active, onClick }: Props) {
  return (
    <button onClick={() => onClick(step)} className={styles.stepContainer}>
      <div className={classNames(styles.step, { [styles.stepActive]: active })}>
        {step + 1}
      </div>
      <p className={active ? styles.stepActiveText : ""}>{title}</p>
    </button>
  );
}
