import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

import { Dropdown } from "components/Dropdowns";
import { Icon } from "components/Icon";

import { classNames } from "lib/classNames";

import styles from "./DropdownFilters.module.scss";

type Props = {
  activeFilters: number;
  onDeleteClick: () => void;
  children: ReactNode;
};

export function DropdownFilters({
  activeFilters,
  onDeleteClick,
  children,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const resetFilters = (e: any) => {
    e.stopPropagation();
    onDeleteClick();
  };

  const filtersTouched = activeFilters > 0;

  return (
    <Dropdown
      backgroundColor={
        filtersTouched ? "var(--color-primary-light)" : undefined
      }
      onChange={() => setIsOpen(!isOpen)}
      noChevron={!isOpen}
      icon={{ name: "filter", intent: "black" }}
      width={isOpen ? 350 : filtersTouched ? 100 : 50}
      btnElement={
        <div
          className={classNames(styles.btnElement, {
            [styles.btnElementOpen]: isOpen || filtersTouched,
          })}
        >
          {isOpen ? (
            <p>
              {filtersTouched
                ? t("filtersNo", {
                    number: activeFilters,
                  })
                : t("filters")}
            </p>
          ) : filtersTouched ? (
            <p>({activeFilters})</p>
          ) : (
            <></>
          )}
          {filtersTouched && (
            <span>
              <Icon name="delete1" size={14} onClick={resetFilters} />
            </span>
          )}
        </div>
      }
    >
      <div className={styles.dropdownContent}>{children}</div>
    </Dropdown>
  );
}
