import { useTranslation } from "react-i18next";

import validator from "@rjsf/validator-ajv8";

import { Form } from "components/Form";

import { Section } from "./Section";

type Props = {
  defaults?: any;
};

export function FormAccountSettings({ defaults }: Props) {
  const { t } = useTranslation();

  const formData = {
    validator,
    uiSchema: {
      "ui:submitButtonOptions": {
        text: t("save"),
        intent: "primary",
        icon: { name: "floppydisk", intent: "white" },
        slim: true,
      },
      general: {
        "ui:widget": Section,
        accountName: {
          "ui:widget": "text",
        },
        country: {
          "ui:widget": "select",
          "ui:placeholder": t("pages.account.form.country"),
          "ui:width": "50%",
        },
        currency: {
          "ui:widget": "select",
          "ui:placeholder": t("pages.account.form.currency"),
          "ui:width": "50%",
        },
      },
      publicInfo: {
        "ui:widget": Section,
        "ui:icon": "usermultiplegroup",
        companyName: {
          "ui:widget": "text",
          "ui:width": "50%",
        },
        companyType: {
          "ui:widget": "text",
          "ui:width": "50%",
        },
      },
      financialInfo: {
        "ui:widget": Section,
        "ui:icon": "Uwallet",
        legalStatus: {
          "ui:widget": "text",
        },
        legalType: {
          "ui:widget": "text",
          "ui:width": "50%",
        },
        legalValue: {
          "ui:widget": "text",
          "ui:width": "50%",
        },
      },
      bankAccounts: {
        "ui:widget": Section,
        "ui:icon": "bank",
        banks: {
          "ui:type": "clear",
          items: {
            accountHolder: {
              "ui:widget": "text",
              "ui:width": "50%",
            },
            bicSwift: {
              "ui:widget": "text",
              "ui:width": "50%",
            },
            iban: {
              "ui:widget": "text",
            },
          },
        },
      },
      representative: {
        "ui:widget": Section,
        "ui:icon": "usercirclesingle",
        representatives: {
          "ui:type": "clear",
          items: {
            title: {
              "ui:widget": "text",
              "ui:width": "20%",
            },
            name: {
              "ui:widget": "text",
              "ui:width": "40%",
            },
            surname: {
              "ui:widget": "text",
              "ui:width": "40%",
            },
            sex: {
              "ui:widget": "radio",
              "ui:width": "40%",
              "ui:options": {
                inline: true,
              },
            },
            position: {
              "ui:widget": "select",
              "ui:width": "60%",
            },
          },
        },
      },
      contact: {
        "ui:widget": Section,
        "ui:icon": "contactphonebook",
        contacts: {
          "ui:type": "clear",
          items: {
            contactType: {
              "ui:widget": "select",
              "ui:width": "50%",
            },
            contactValue: {
              "ui:widget": "text",
              "ui:width": "50%",
            },
          },
        },
      },
      addresses: {
        "ui:widget": Section,
        "ui:icon": "home1",
        address: {
          "ui:type": "clear",
          items: {
            country: {
              "ui:widget": "select",
              "ui:placeholder": t("pages.account.form.country"),
              "ui:width": "50%",
            },
            zip: {
              "ui:widget": "text",
              "ui:width": "50%",
            },
            city: {
              "ui:widget": "text",
              "ui:width": "50%",
            },
            addressLine: {
              "ui:widget": "text",
              "ui:width": "50%",
            },
          },
        },
      },
    },
    schema: {
      type: "object",
      required: [
        "general",
        "publicInfo",
        "financialInfo",
        "bankAccounts",
        "representative",
        "contact",
      ],
      properties: {
        general: {
          type: "object",
          required: ["accountName", "country", "currency"],
          title: t("pages.account.form.general"),
          properties: {
            accountName: {
              type: "string",
              title: t("pages.account.form.accountName"),
              default: defaults?.account,
            },
            country: {
              type: "string",
              title: t("pages.account.form.country"),
              enum: t("countries", { returnObjects: true }),
              default: defaults?.details?.country ?? "",
            },
            currency: {
              type: "string",
              title: t("pages.account.form.currency"),
              enum: ["GBP", "EUR", "USD"],
              default: defaults?.details?.currency ?? "",
            },
          },
        },
        publicInfo: {
          type: "object",
          required: ["companyName", "companyType"],
          title: t("pages.account.form.publicInfo"),
          properties: {
            companyName: {
              type: "string",
              title: t("pages.account.form.companyName"),
              default: defaults?.team,
            },
            companyType: {
              type: "string",
              title: t("pages.account.form.type"),
            },
          },
        },
        financialInfo: {
          type: "object",
          required: ["legalStatus", "legalType", "legalValue"],
          title: t("pages.account.form.financialInfo"),
          properties: {
            legalStatus: {
              type: "string",
              title: t("pages.account.form.legalStatus"),
              default: defaults?.details?.legalStatus ?? "",
            },
            legalType: {
              type: "string",
              title: t("pages.account.form.type"),
            },
            legalValue: {
              type: "string",
              title: t("pages.account.form.value"),
            },
          },
        },
        bankAccounts: {
          type: "object",
          title: t("pages.account.form.bankAccounts"),
          properties: {
            banks: {
              type: "array",
              default: defaults?.details?.bankAccounts ?? "",
              items: {
                type: "object",
                title: "",
                required: ["accountHolder", "bicSwift", "iban"],
                minItems: 1,
                properties: {
                  accountHolder: {
                    type: "string",
                    title: t("pages.account.form.accountHolder"),
                  },
                  bicSwift: {
                    type: "string",
                    title: t("pages.account.form.bicSwift"),
                  },
                  iban: {
                    type: "string",
                    title: t("pages.account.form.iban"),
                  },
                },
              },
            },
          },
        },
        representative: {
          type: "object",
          title: t("pages.account.form.representative"),
          properties: {
            representatives: {
              type: "array",
              default: defaults?.details?.representatives ?? "",
              items: {
                type: "object",
                title: "",
                required: ["title", "name", "surname", "sex", "position"],
                minItems: 1,
                properties: {
                  title: {
                    type: "string",
                    title: t("pages.account.form.title"),
                  },
                  name: {
                    type: "string",
                    title: t("pages.account.form.name"),
                  },
                  surname: {
                    type: "string",
                    title: t("pages.account.form.surname"),
                  },
                  sex: {
                    type: "string",
                    title: t("pages.account.form.sex"),
                    enum: ["Male", "Female"],
                  },
                  position: {
                    type: "string",
                    title: t("pages.account.form.position"),
                    enum: ["Laywer", "Owner", "Worker"],
                  },
                },
              },
            },
          },
        },
        contact: {
          type: "object",
          title: t("pages.account.form.contacts"),
          properties: {
            contacts: {
              type: "array",
              default: defaults?.details?.contacts ?? "",
              items: {
                type: "object",
                title: "",
                required: ["contactType", "contactValue"],
                minItems: 1,
                properties: {
                  contactType: {
                    type: "string",
                    title: t("pages.account.form.type"),
                    enum: ["Phone", "Email"],
                  },
                  contactValue: {
                    type: "string",
                    title: t("pages.account.form.value"),
                  },
                },
              },
            },
          },
        },
        addresses: {
          type: "object",
          title: t("pages.account.form.addresses"),
          properties: {
            address: {
              type: "array",
              default: defaults?.details?.address ?? "",
              items: {
                type: "object",
                title: "",
                required: ["country", "zip", "city", "addressLine"],
                minItems: 1,
                properties: {
                  country: {
                    type: "string",
                    title: t("pages.account.form.country"),
                    enum: t("countries", { returnObjects: true }),
                  },
                  zip: {
                    type: "string",
                    title: t("pages.account.form.zip"),
                  },
                  city: {
                    type: "string",
                    title: t("pages.account.form.city"),
                  },
                  addressLine: {
                    type: "string",
                    title: t("pages.account.form.addressLine"),
                  },
                },
              },
            },
          },
        },
      },
    },
  };

  return <Form noHtml5Validate={true} {...formData} />;
}
