import { CheckboxesWidget } from "components/Form/CheckboxWidget";

import styles from "./Filters.module.scss";

type Props = {
  title: string;
  value: string;
  filters: any[];
  onChange: (value: any) => void;
};

export function Filters({ title, value, filters, onChange }: Props) {
  return (
    <div className={styles.filtersContainer}>
      <p>{title}</p>
      {/* @ts-expect-error not all props */}
      <CheckboxesWidget
        options={{
          enumOptions: filters,
        }}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}
