export function formatNumber(num: number, shortenNumbers = false): string {
  const formatter = new Intl.NumberFormat("en-US", {
    notation: shortenNumbers ? "compact" : "standard",
  });

  return formatter.format(num);
}

export function formatCurrency(num: number, currency = "EUR"): string {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    currencyDisplay: "code",
    currencySign: "accounting",
  }).format(num);
}
