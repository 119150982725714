import { createColumnHelper } from "@tanstack/react-table";

import { Pill } from "components/Pill";

const columnHelper = createColumnHelper<CustomerRes>();

export const customerColumns = [
  columnHelper.accessor("customer.customerName", {
    header: "Name",
    cell: (info) => info.getValue(),
    meta: {
      headerIcon: "usercirclesingle",
      filterType: "text",
    },
  }),
  columnHelper.accessor("customer.customerNo", {
    header: "Customer NO",
    cell: (info) => (
      <Pill
        text={info.row.original.customer.customerNo}
        intent="Link"
        href={`/customer/${info.row.original.customer.id}`}
      />
    ),
    meta: {
      headerIcon: "userprofilefocus",
      filterType: "text",
    },
  }),
  columnHelper.accessor("customer.customerAddress.countryCode", {
    header: "Country",
    cell: (info) => info.getValue(),
    meta: {
      headerIcon: "earth",
      filterType: "dropdown",
    },
  }),
  columnHelper.accessor("customerTotalRemainingBalance", {
    header: "Total Remaining Balance",
    cell: (info) => `${info.getValue()}`,
    meta: {
      headerIcon: "bagdollar",
      filterType: "text",
    },
  }),
  columnHelper.accessor("numberOfActiveCases", {
    header: "Open Cases",
    cell: (info) => info.getValue(),
    meta: {
      headerIcon: "streamlineCoreLineFileText",
      filterType: "text",
    },
  }),
];

export const customerCOColumns = [
  customerColumns[0],
  customerColumns[1],
  columnHelper.accessor("customer.clientId", {
    header: "Client",
    cell: (info) => info.getValue() ?? info.row.original.customer.clientId,
    meta: {
      headerIcon: "usermultiplegroup",
    },
  }),
  ...customerColumns.slice(2),
];
