import { Button } from "components/Button";
import { Icon } from "components/Icon";
import { Modal, getIcon } from "components/Modal";
import modalStyles from "components/Modal/Modal.module.scss";

import { classNames } from "lib/classNames";

import styles from "./ModalAction.module.scss";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  intent: string;
  title: string;
  content?: () => JSX.Element;
  text?: string;
  ctaBtns: any[];
  direction?: "row" | "column";
};

export function ModalAction({
  isOpen,
  onClose,
  title,
  text,
  content,
  intent = "info",
  ctaBtns = [],
  direction = "row",
}: Props) {
  const icon = getIcon(intent);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div
        className={classNames(styles.modalContainer, {
          [styles.modalContainerReverse]: direction === "column",
        })}
      >
        {/* @ts-expect-error type error */}
        <div className={modalStyles[`iconContainer-${intent}`]}>
          {icon && <Icon name={icon} />}
        </div>
        <div className={styles.content}>
          <h2>{title}</h2>
          {content ? content() : <p>{text}</p>}
        </div>
      </div>
      <div className={styles.ctaBtns}>
        {ctaBtns.map((cta, i) => (
          <Button key={i} {...cta} />
        ))}
      </div>
    </Modal>
  );
}
