import { CoreHeader } from "@tanstack/react-table";
import { ChangeEvent, useMemo } from "react";

import { RInioInput } from "ui-core";
import { RInioInputSelect } from "ui-core/components/InputSelect/InputSelect";

import { Filters } from "components/Filters";
import { DateWidget } from "components/Form/DateWidget";
import { FieldTemplate } from "components/Form/FieldTemplate";
import { Range } from "components/Range";

type Props<TData> = {
  column: CoreHeader<TData, unknown>["column"];
};

export function TableFilter<TData>({ column }: Props<TData>) {
  const label = column.columnDef.header;
  const filterType = column.columnDef.meta?.filterType;

  const sortedUniqueValues = useMemo(
    () =>
      typeof column.getFacetedUniqueValues().keys().next().value === "number"
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column],
  );

  switch (filterType) {
    case "checkboxes":
      return (
        <Filters
          title={column.id}
          value={column.getFilterValue() as string}
          onChange={(value) => {
            column.setFilterValue(value);
          }}
          filters={sortedUniqueValues.map((f) => ({ value: f }))}
        />
      );
    case "text":
      return (
        <RInioInput
          label={label}
          placeholder="Search..."
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            column.setFilterValue(e.target.value?.toLowerCase());
          }}
        />
      );
    case "dropdown":
      return (
        <RInioInputSelect
          label={label}
          onChange={(value: string) => {
            column.setFilterValue(value);
          }}
          // value={(column.getFilterValue() as string[]) ?? ""}
          options={sortedUniqueValues.map((f) => ({
            value: f,
            label: f,
          }))}
        />
      );
    case "range":
      return (
        // @ts-expect-error not all props
        <FieldTemplate label={label}>
          <Range
            min={column.getFacetedMinMaxValues()?.[0]}
            max={column.getFacetedMinMaxValues()?.[1]}
            value={
              (column.getFilterValue() as [number, number])?.[1] ??
              column.getFacetedMinMaxValues()?.[1]
            }
            onChange={({ target }: any) =>
              column.setFilterValue([0, parseInt(target.value, 10)])
            }
          />
        </FieldTemplate>
      );
    case "date":
      return (
        // @ts-expect-error not all props
        <FieldTemplate label={label}>
          <DateWidget
            value={column.getFilterValue() as [Date, Date]}
            onChange={(value) =>
              !value.startDate && !value.endDate
                ? column.setFilterValue(undefined)
                : column.setFilterValue([value.startDate, value.endDate])
            }
            range={true}
          />
        </FieldTemplate>
      );
    default:
      return null;
  }
}
