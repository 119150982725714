import { CSSProperties } from "react";

import { ObjectFieldTemplateProps } from "@rjsf/utils";

import { classNames } from "lib/classNames";

import styles from "./ObjectFieldTemplate.module.scss";

interface CustomStyles extends CSSProperties {
  "--width": string;
}

export function ObjectFieldTemplate(props: ObjectFieldTemplateProps) {
  const fieldsetProps = props.uiSchema?.["ui:fieldset"] || {};
  const errorProps = props.uiSchema?.["ui:error"] || {};
  const customWidget = props.uiSchema?.["ui:widget"];

  if (customWidget) {
    // @ts-expect-error type error
    return customWidget(props);
  }

  return (
    <fieldset
      className={classNames(styles.fieldset, {
        [styles.fieldsetBorder]: fieldsetProps?.border,
      })}
    >
      {errorProps?.message && (
        <p className={styles.errorMsg}>{errorProps.message}</p>
      )}
      <p className={styles.title}>{props.title}</p>
      {props.description}
      {props.properties.map((element, i) => (
        <div
          key={i}
          className={styles.formFieldContainer}
          style={
            {
              "--width": element.content.props.uiSchema?.["ui:width"] ?? "100%",
            } as CustomStyles
          }
        >
          {element.content}
        </div>
      ))}
    </fieldset>
  );
}
