import { ChangeEvent } from "react";

import {
  enumOptionsSelectValue,
  enumOptionsDeselectValue,
  enumOptionsValueForIndex,
  WidgetProps,
} from "@rjsf/utils";

import { Icon } from "components/Icon";

import { classNames } from "lib/classNames";

import styles from "./CheckboxWidget.module.scss";

export function CheckboxesWidget(props: WidgetProps) {
  const {
    id,
    name,
    disabled,
    options,
    readonly,
    autofocus: autoFocus,
    required,
    value,
    onChange,
    onBlur,
    onFocus,
  } = props;
  const { enumOptions, emptyValue, inline } = options;
  const checkboxesValues = value
    ? Array.isArray(value)
      ? value
      : [value]
    : [];

  const _onChange = (
    { target: { checked } }: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (checked) {
      onChange(enumOptionsSelectValue(index, checkboxesValues, enumOptions));
    } else {
      onChange(enumOptionsDeselectValue(index, checkboxesValues, enumOptions));
    }
  };
  const _onBlur = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    onBlur?.(id, enumOptionsValueForIndex(value, enumOptions, emptyValue));
  const _onFocus = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    onFocus?.(id, enumOptionsValueForIndex(value, enumOptions, emptyValue));

  return (
    <div
      className={classNames(styles.checkboxesContainer, {
        [styles.checkboxesContainerInline]: inline ? true : false,
      })}
    >
      {/* @ts-expect-error icon not in enumOptions */}
      {options.enumOptions?.map(({ label, value, icon }, i) => (
        <label key={i}>
          <input
            id={id}
            name={name}
            type="checkbox"
            disabled={disabled || readonly}
            required={required}
            autoFocus={autoFocus}
            value={value}
            checked={checkboxesValues.includes(value)}
            onChange={(e) => _onChange(e, i)}
            onBlur={_onBlur}
            onFocus={_onFocus}
          />
          {icon && <Icon name={icon} size={16} />}
          {label ?? value}
        </label>
      ))}
    </div>
  );
}
