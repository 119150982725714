import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Card } from "components/Card/Card";
import { DashboardHeader } from "components/DashboardHeader";
import { CustomerProfile } from "components/PageCustomer/CustomerProfile";
import { customerCaseColumns } from "components/PageCustomer/TableCustomerCases";
import { Table } from "components/Table";

import { useHead } from "lib/hooks/useHead";
import { useGetCustomerQuery } from "lib/slices/clientServiceAPISlice";

import styles from "./PageCustomer.module.scss";

export function PageCustomer() {
  const { customerId } = useParams() as { customerId: string };
  const { t } = useTranslation();
  const { data } = useGetCustomerQuery(customerId);

  useHead(data?.customer.customerName || t("pages.customer.profile"));

  if (!data) {
    return (
      <h2 className={styles.noSuchCustomer}>
        {t("pages.customer.noCustomer")}
      </h2>
    );
  }

  return (
    <main className={styles.main}>
      <DashboardHeader
        title={t("pages.customer.profile")}
        breadcrumbs={[
          { title: "Customers & Cases", link: "/" },
          { title: data?.customer.customerName || "" },
        ]}
        backBtn={true}
      />

      <section className={styles.content}>
        <CustomerProfile customer={data.customer} />

        <div className={styles.tableContainer}>
          <Card>
            <div className={styles.tableInnerContainer}>
              <div className={styles.tableInnerHeader}>
                <h1>{t("pages.customer.casesTableHeader")}</h1>
                <p>{t("pages.customer.casesTableSubheader")}</p>
              </div>
              <Table
                data={data.cases}
                columns={customerCaseColumns}
                header={true}
                borderedTable={true}
              />
            </div>
          </Card>
        </div>
      </section>
    </main>
  );
}
