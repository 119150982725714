import { useTranslation } from "react-i18next";

import { Dropdown } from "components/Dropdowns";

import styles from "./CustomerSegmentDropdown.module.scss";

export function CustomerSegmentDropdown() {
  const { t } = useTranslation();

  return (
    <span className={styles.dropdownContainer}>
      <Dropdown
        btnElement={<>{t("pages.customer.profileTypes.standard")}</>}
        theme="select"
      >
        {/* Dropdown Content */}
        <div className={styles.dropdownContent}>
          {/* Standard Profile */}
          <div>
            <input type="radio" id="default-profile" name="profile" />
            <label htmlFor="default-profile">
              {t("pages.customer.profileTypes.default")}
            </label>
          </div>
          {/* VIP Profile */}
          <div>
            <input
              type="radio"
              id="vip-profile"
              name="profile"
              value={t("pages.customer.profileTypes.vip")}
            />
            <label htmlFor="vip-profile">
              {t("pages.customer.profileTypes.vip")}
            </label>
          </div>
          {/* Standard Profile */}
          <div>
            <input
              type="radio"
              id="standard-profile"
              name="profile"
              value={t("pages.customer.profileTypes.standard")}
              defaultChecked={true}
            />
            <label htmlFor="standard-profile">
              {t("pages.customer.profileTypes.standard")}
            </label>
          </div>
          {/* Customized Profile */}
          <div>
            <input
              type="radio"
              id="customized-profile"
              name="profile"
              value={t("pages.customer.profileTypes.customized")}
            />
            <label htmlFor="customized-profile">
              {t("pages.customer.profileTypes.customized")}
            </label>
          </div>
        </div>
      </Dropdown>
    </span>
  );
}
