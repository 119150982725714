import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";

import { DashboardHeader } from "components/DashboardHeader";

import styles from "./PagePayments.module.scss";

export function PagePayments() {
  const { t } = useTranslation();
  const auth = useAuth();

  return (
    <>
      <main className={styles.main}>
        <DashboardHeader
          title={t("pages.payments.header")}
          subHeader={
            auth.user?.profile.clientName
              ? t("pages.payments.subHeader", {
                  clientName: auth.user?.profile.clientName,
                })
              : t("pages.payments.subHeaderInioOperator")
          }
        />
      </main>
    </>
  );
}
