import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import { Payment } from "types";

import { Table } from "components/Table";
import { Text } from "components/Text";

import { formatDateToString } from "lib/dateUtils";

type Props = {
  payments: Payment[];
};

const columnHelper = createColumnHelper<Payment>();

export function TablePayments({ payments }: Props) {
  const { t } = useTranslation();

  const columns = [
    columnHelper.accessor("createdAt", {
      header: t("pages.case.tableColumns.paymentDate&Method"),
      cell: (info) => (
        <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
          <span>{formatDateToString(info.row.original.createdAt)}</span>
          <span style={{ textTransform: "capitalize" }}>
            {info.row.original.paymentMethod}
          </span>
        </div>
      ),
      meta: {
        filterType: "text",
        headerIcon: "blankcalendar",
      },
    }),
    columnHelper.accessor("amount", {
      header: t("pages.case.tableColumns.amount"),
      cell: (info) => (
        <>
          {info.row.original.amount} {info.row.original.currency}
        </>
      ),
      meta: {
        filterType: "text",
        headerIcon: "bagdollar",
      },
    }),
    columnHelper.accessor("paymentReference", {
      header: t("pages.case.tableColumns.paymentReference"),
      meta: {
        filterType: "text",
        headerIcon: "filecode",
      },
    }),
    columnHelper.accessor("payeePaymentReference", {
      header: t("pages.case.tableColumns.transactionReference"),
      meta: {
        filterType: "text",
        headerIcon: "streamlineCoreLineFileText",
      },
    }),
  ];

  if (payments.length === 0) {
    return (
      <div style={{ textAlign: "center" }}>
        <Text as="p" style="body">
          No payments found
        </Text>
      </div>
    );
  }

  return (
    <Table
      data={payments}
      columns={columns}
      header={true}
      borderedTable={true}
    />
  );
}
