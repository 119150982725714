import { ChangeEvent } from "react";

import { WidgetProps } from "@rjsf/utils";
import { RInioInputSelect } from "ui-core/components/InputSelect/InputSelect";

/**
 * Select Component
 * A select and multi-select component replacing rjsf's SelectWidget.
 *
 * uiSchema:
 * @param {string} [props.uiSchema.icon] - Optional icon name to display
 */
export function SelectWidget(props: WidgetProps) {
  const { options, onChange, value: formValue, multiple, label } = props;

  function handleOnChange({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) {
    if (multiple) {
      // TODO: RInioInputSelect does not support multiple options
      // const newValue = value.includes(optionValue)
      //   ? value.filter((v: any) => v !== optionValue)
      //   : [...value, optionValue];
      // onChange(newValue);
    } else {
      value === formValue ? onChange(undefined) : onChange(value);
    }
  }

  return (
    <RInioInputSelect
      label={label}
      options={options.enumOptions}
      onChange={handleOnChange}
      value={formValue}
    />
  );
}
