import { RInioInput } from "ui-core";

type Props = {
  placeholder: string;
  onChange: (value: string) => void;
  value?: string;
};

export function SearchBox({ placeholder, onChange }: Props) {
  return (
    <RInioInput
      placeholder={placeholder}
      // @ts-expect-error rinioinput returning type
      onChange={({ target: { value } }) => onChange(value)}
      style={{ width: "100%" }}
    />
  );
}
