import { useAuth } from "react-oidc-context";
import { Route, Routes } from "react-router-dom";

import { PrivateSARoute } from "components/PrivateSARoute";
import { Sidebar } from "components/Sidebar";
import sidebarConfig from "components/Sidebar/sidebar.config.json";

import { PageCase } from "pages/Case";
import { PageCustomer } from "pages/Customer";
import { PageCustomersCases } from "pages/Customers-Cases";
import { PagePayments } from "pages/Payments/PagePayments";
import { PageAccount, PageMembers } from "pages/Settings";
import { PageSAClients } from "pages/SuperAdmin";
import { PageSAClientsEdit } from "pages/SuperAdmin/Clients/PageSAClientsEdit";
import { PageSAClientsNew } from "pages/SuperAdmin/Clients/PageSAClientsNew";

import styles from "./LayoutDashboard.module.scss";

export function LayoutDashboard() {
  const auth = useAuth();

  if (!auth.user) {
    return;
  }

  return (
    <div className={styles.layout}>
      <Sidebar
        config={sidebarConfig}
        dataUser={auth.user}
        logOutHandler={() => auth.signoutRedirect()}
      />
      <Routes>
        <Route
          path="/"
          element={<PageCustomersCases defaultView="customer" />}
        />

        <Route path="/payments" element={<PagePayments />} />

        <Route
          path="/customers"
          element={<PageCustomersCases defaultView="customer" />}
        />
        <Route
          path="/cases"
          element={<PageCustomersCases defaultView="case" />}
        />

        <Route path="/customer/:customerId/:caseId" element={<PageCase />} />
        <Route path="/customer/:customerId" element={<PageCustomer />} />

        <Route path="/settings/general" element={<PageAccount />} />
        <Route path="/settings/members" element={<PageMembers />} />

        {/* Super Admin Routes */}
        <Route
          path="/sa/clients"
          element={<PrivateSARoute children={<PageSAClients />} />}
        />
        <Route
          path="/sa/clients/new"
          element={<PrivateSARoute children={<PageSAClientsNew />} />}
        />
        <Route
          path="/sa/clients/:clientId"
          element={<PrivateSARoute children={<PageSAClientsEdit />} />}
        />
      </Routes>
    </div>
  );
}
