import { useTranslation } from "react-i18next";
import validator from "@rjsf/validator-ajv8";

import { Form } from "components/Form";

export function FormEntity() {
  const { t } = useTranslation();

  const formData = {
    validator,
    uiSchema: {
      "ui:submitButtonOptions": {
        text: t("save"),
        intent: "primary",
        icon: { name: "floppydisk", intent: "white" },
        slim: true,
      },
      reference: {
        "ui:widget": "text",
      },
      type: {
        "ui:widget": "radio",
        "ui:options": {
          inline: true,
        },
      },
      title: {
        "ui:widget": "text",
        "ui:width": "30%",
      },
      givenNames: {
        "ui:widget": "text",
        "ui:width": "70%",
      },
      surname: {
        "ui:widget": "text",
      },
    },
    schema: {
      type: "object",
      required: ["reference", "type", "title", "givenNames", "surname"],
      properties: {
        reference: {
          type: "string",
          title: t("formEntity.reference"),
        },
        type: {
          type: "string",
          title: t("formEntity.type"),
          enum: [t("formEntity.person"), t("formEntity.organisation")],
        },
        title: {
          type: "string",
          title: t("formEntity.title"),
        },
        givenNames: {
          type: "string",
          title: t("formEntity.givenNames"),
        },
        surname: {
          type: "string",
          title: t("formEntity.surname"),
        },
      },
    },
  };

  return <Form {...formData} />;
}
