import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { HOST_CLIENT_SERVICE } from "config/common";
import { userManager } from "index";

import { appStorage } from "lib/localStorage";

const baseQuery = fetchBaseQuery({
  baseUrl: HOST_CLIENT_SERVICE,
  credentials: "include",
  prepareHeaders: (headers) => {
    const storageKey = `oidc.user:${process.env.REACT_APP_KEYCLOAK_AUTHORITY}/${process.env.REACT_APP_KEYCLOAK_REALM}:${process.env.REACT_APP_KEYCLOAK_CLIENT}`;
    const clientId = JSON.parse(sessionStorage.getItem(storageKey) ?? "{}")
      .profile?.clientId;

    const token = JSON.parse(
      sessionStorage.getItem(storageKey) ?? "{}",
    ).access_token;

    headers.set("Authorization", `Bearer ${token}`);
    clientId && headers.set("X-Client-Id", clientId);

    return headers;
  },
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    await userManager.signoutRedirect();
    // We could refresh the JWT token here in the future instead
    // of redirecting back to the /login page. Depends on our auth strategy.
  }
  return result;
};

export const clientServiceAPISlice = createApi({
  reducerPath: "client-service-api",
  tagTypes: ["Client"],
  baseQuery: baseQueryWithReauth,
  serializeQueryArgs: ({ endpointName, queryArgs }) => {
    const clientId = appStorage.getItem<string>("clientId");
    const clientSuffix = clientId ? `-${clientId}` : "";

    if (typeof queryArgs === "string") {
      // If queryArgs is a string (e.g., an ID), include it in the cache key
      return `${endpointName}-${queryArgs}${clientSuffix}`;
    } else if (queryArgs && typeof queryArgs === "object") {
      // If queryArgs is an object, you can pick specific properties
      // For example, cache based on 'status' property if it exists
      const { status } = queryArgs;
      const querySuffix = status ? `-${status}` : "";
      return `${endpointName}${querySuffix}${clientSuffix}`;
    }
    // For endpoints without queryArgs, use the endpoint name and clientId as the cache key
    return `${endpointName}${clientSuffix}`;
  },
  endpoints: (builder) => ({
    // SA Routes
    getClients: builder.query<{ data: Client[]; totalResults: number }, void>({
      query: () => "/clients",
      providesTags: ["Client"],
    }),
    getClient: builder.query<Client, string>({
      query: (clientId) => `/clients/${clientId}`,
      providesTags: ["Client"],
    }),
    addClient: builder.mutation({
      query: (body) => ({
        url: "/clients",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Client"],
    }),
    updateClient: builder.mutation<
      string,
      { id: string; body: Partial<Client> }
    >({
      query: ({ id, body }) => ({
        url: `/clients/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Client"],
    }),
    deleteClient: builder.mutation({
      query: (id) => ({
        url: `/clients/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Client"],
    }),

    // Customers endpoints
    getCustomers: builder.query<CustomerRes[], number>({
      query: (page) => `/customers?page=${page}&limit=50`,
    }),
    getCustomer: builder.query<SingleCustomerRes, string>({
      query: (id) => `/customers/${id}`,
    }),

    // Cases endpoints
    getCases: builder.query<Case[], number>({
      query: (page) => `/cases?page=${page}&limit=50`,
    }),
    getCaseById: builder.query<SingleCaseRes, string>({
      query: (id) => `/cases/${id}`,
    }),

    getMembers: builder.query<Member[], void>({
      query: () => "/clients/members",
    }),
    createMember: builder.mutation({
      query: (body) => ({
        url: "/clients/members",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Client"],
    }),
    editMember: builder.mutation({
      query: (body) => ({
        url: `/clients/members/${body.userId}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Client"],
    }),
    deleteMember: builder.mutation({
      query: (body) => ({
        url: `/clients/members/${body.id}`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Client"],
    }),
  }),
});

export const {
  useGetCustomerQuery,
  useGetCustomersQuery,
  useGetClientsQuery,
  useAddClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useGetCaseByIdQuery,
  useGetCasesQuery,
  useGetMembersQuery,
  useCreateMemberMutation,
  useDeleteMemberMutation,
  useEditMemberMutation,
  useGetClientQuery,
} = clientServiceAPISlice;
