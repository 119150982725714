import { RefObject, useEffect } from "react";

/**
 * Detects and responds to clicks outside of a specified element.
 *
 * @param {React.RefObject} ref - The React ref object attached to the element you want to monitor for outside clicks.
 * @param {Function} callback - The callback function to execute when a click outside the monitored element is detected.
 */
export function useOutsideClick(ref: RefObject<any>, callback: () => void) {
  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}
