import { useState, useRef, CSSProperties } from "react";

import { icons } from "assets/icons";

import { Icon } from "components/Icon";
import { Text } from "components/Text";

import { useOutsideClick } from "lib/hooks/useOutsideClick";

import styles from "./ContextMenu.module.scss";

type MenuItem = {
  label: string;
  icon?: keyof typeof icons;
  onClick: () => void;
};

type Props = {
  children: React.ReactNode;
  menuItems: MenuItem[];
};

export function ContextMenu({ children, menuItems }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick(buttonRef, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  const handleToggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleCloseMenu = () => {
    setIsOpen(false);
  };

  return (
    <div className={styles.contextMenuWrapper} ref={buttonRef}>
      <div onClick={handleToggleMenu} className={styles.trigger}>
        {children}
      </div>

      {isOpen && (
        <div className={styles.menu} style={getMenuPosition(buttonRef)}>
          {menuItems.map((item, index) => (
            <div
              key={index}
              className={styles.menuItem}
              onClick={() => {
                item.onClick();
                handleCloseMenu();
              }}
            >
              {item.icon && <Icon size={18} name={item.icon} />}
              <Text as="p" style="bodySmall">
                {item.label}
              </Text>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

// Helper function to calculate the position of the menu
function getMenuPosition(
  buttonRef: React.RefObject<HTMLDivElement>,
): CSSProperties {
  if (!buttonRef.current) return {};

  const rect = buttonRef.current.getBoundingClientRect();
  const viewportWidth = window.innerWidth;
  const menuWidth = 200; // Assume a fixed menu width for calculation

  let left = rect.left + window.scrollX;
  if (left + menuWidth > viewportWidth) {
    left = viewportWidth - menuWidth - 24; // Ensure it doesn't go beyond the screen, with some padding
  }

  return {
    position: "fixed",
    top: `${rect.bottom + window.scrollY}px`,
    left: `${left}px`,
  };
}
