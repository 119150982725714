import { useTranslation } from "react-i18next";

import { DashboardHeader } from "components/DashboardHeader";
import { FormAccountSettings } from "components/FormAccountSettings";

import { useHead } from "lib/hooks/useHead";

import styles from "./PageAccount.module.scss";

export function PageAccount() {
  const { t } = useTranslation();
  useHead(t("pages.account.header"));

  return (
    <main className={styles.main}>
      <DashboardHeader title={t("pages.account.header")} />
      <FormAccountSettings />
    </main>
  );
}
