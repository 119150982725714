import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { Icon } from "components/Icon";

import styles from "../WizardNewClaim.module.scss";

type Props = {
  formData: {
    entity: string;
    claims: [];
  };
  resetForm: () => void;
  onCloseModal: () => void;
};

export function StepDone({ formData, resetForm, onCloseModal }: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.icon}>
          <Icon name="check" intent="valid" />
        </div>
        <h2>{t("newClaim.success")}</h2>
        <p>{t("newClaim.overview")}</p>
      </div>
      <div className={styles.overviewContainer}>
        <div className={styles.entity}>
          <div>
            <span>
              <Icon name="usercirclesingle" intent="primary" />
            </span>
            <div>
              <p>{formData.entity}</p>
              <p>{t("newClaim.chosenEntity")}</p>
            </div>
          </div>
          <div>
            <p>{t("newClaim.entityId")}</p>
            <p>cus-61H8X4V2-90SX1PS5</p>
          </div>
        </div>
        {formData.claims?.map((_, index) => (
          <div className={styles.claim} key={index}>
            <div>
              <span>
                <Icon name="newfile" intent="primary" />
              </span>
              <div>
                <p>{formData.entity}</p>
                <p>{t("newClaim.claimNo", { number: index + 1 })}</p>
              </div>
            </div>
            <div>
              <p>{t("newClaim.fileId")}</p>
              <p>fil-65R8X4V192F1SP2</p>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.footer}>
        <Button text={t("newClaim.resetForm")} onClick={resetForm} />
        <Button text={t("close")} intent="primary" onClick={onCloseModal} />
      </div>
    </div>
  );
}
