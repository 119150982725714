import { useTranslation } from "react-i18next";

import { DashboardHeader } from "components/DashboardHeader";
import { FormSANewClient } from "components/FormSANewClient";

import styles from "./PageSAClients.module.scss";

export function PageSAClientsNew() {
  const { t } = useTranslation();

  return (
    <main className={styles.main}>
      <DashboardHeader
        title={t("pages.superAdmin.clients.header")}
        breadcrumbs={[
          { title: "Clients", link: "/sa/clients" },
          { title: "New Client" },
        ]}
      />

      <section className={styles.clientNewContainer}>
        <div>
          <h3>{t("pages.superAdmin.clients.newClient")}</h3>
          <p>{t("pages.superAdmin.clients.newClientSubheader")}</p>
        </div>

        <FormSANewClient />
      </section>
    </main>
  );
}
