import { Fragment } from "react";

import { Step } from "./Step";
import styles from "./StepWizard.module.scss";

type Props = {
  activeStep: number;
  onStepClick: (step: number) => void;
  steps: any[];
};

/**
 * StepWizard Component
 *
 * This component renders a wizard-style interface for navigating through a series of steps.
 * Each step is represented by a title and an associated React element.
 * State of the Wizard is controlled from the parent.
 *
 * @example
 * See DemoWizardForm.js
 */
export function StepWizard({ activeStep, onStepClick, steps }: Props) {
  return (
    <div>
      <div className={styles.steps}>
        {steps.map(({ title }, index) => (
          <Fragment key={index}>
            <Step
              title={title}
              step={index}
              active={activeStep >= index}
              onClick={(step) => onStepClick(step)}
            />
            {index !== steps.length - 1 && (
              <span className={styles.dashedLine} />
            )}
          </Fragment>
        ))}
      </div>
      <div>{steps[activeStep].element}</div>
    </div>
  );
}
