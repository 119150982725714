import styles from "./Skeleton.module.scss";

type Props = {
  width?: string | number;
  height?: string | number;
};

export function Skeleton({ width, height }: Props) {
  return <div className={styles.skeleton} style={{ width, height }} />;
}
