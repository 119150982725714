import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { WidgetProps } from "@rjsf/utils";

import { Button } from "components/Button";
import { Icon } from "components/Icon";

import styles from "./FileWidget.module.scss";

export function FileWidget(props: WidgetProps) {
  const { t } = useTranslation();
  const { value, onChange } = props;

  const onFileChange = ({
    target: { files },
  }: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = files && Array.from(files);
    onChange?.(selectedFiles);
  };

  const onDeleteFile = (fileName: string) => {
    onChange?.(value.filter((file: File) => file.name !== fileName));
  };

  const isImage = (file: File) => {
    const fileName = file.name;
    return (
      /\.(jpg|jpeg|png|gif|webp)$/i.test(fileName) ||
      file.type.startsWith("image/")
    );
  };

  const getImageSrc = (file: File) => {
    if (file.size === 0) {
      return file.name; // Use file name as URL if size is 0
    }
    return URL.createObjectURL(file); // Create object URL for non-empty files
  };

  if (value?.length > 0) {
    return (
      <div className={styles.uploadedFiles}>
        {value.map((file: File, index: number) => (
          <div key={index} className={styles.file}>
            <div>
              {isImage(file) ? (
                <img
                  src={getImageSrc(file)}
                  alt={file.name}
                  className={styles.preview}
                />
              ) : (
                <>
                  <Icon name="newfile" size={16} intent="primary" />
                  <p>{file.name}</p>
                </>
              )}
            </div>
            <Button intent="clear" onClick={() => onDeleteFile(file.name)}>
              <Icon name="delete1" size={14} />
            </Button>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={styles.fileInputContainer}>
      <input type="file" multiple onChange={onFileChange} accept="image/*" />
      <Icon name="cloudadd" />
      <p>{t("fileWidget.text")}</p>
      <Button
        icon={{ name: "add", intent: "white", size: 18 }}
        intent="primary"
      />
    </div>
  );
}
