import { CoreRow, createColumnHelper } from "@tanstack/react-table";

import { t } from "i18next";

import { Badge } from "components/Badge";
import { Button } from "components/Button";

import styles from "./PageMembers.module.scss";

const columnHelper = createColumnHelper<Member>();

export const tableMemberColumns = (
  selectedRows: CoreRow<Member>[],
  addForDeletion: (arg0: CoreRow<Member>) => void,
  setEditModalOpen: (arg0: Member) => void,
  COColumn: boolean,
  userEmail?: string,
) => {
  const columns = [
    columnHelper.accessor("firstName", {
      header: t("pages.members.tableColumns.name"),
      meta: {
        filterType: "text",
      },
    }),
    columnHelper.accessor("lastName", {
      header: t("pages.members.tableColumns.surname"),
      meta: {
        filterType: "text",
      },
    }),
    columnHelper.accessor("email", {
      header: t("pages.members.tableColumns.email"),
      cell: ({ row }) => (
        <p>
          {row.original.email}{" "}
          <strong>
            {userEmail === row.original.email ? "(you)" : undefined}
          </strong>
        </p>
      ),
    }),
    columnHelper.accessor("role", {
      header: t("pages.members.tableColumns.role"),
      filterFn: "arrIncludesSome",
      meta: {
        filterType: "checkboxes",
      },
      cell: ({ row }) => <p style={{ fontWeight: 500 }}>{row.original.role}</p>,
    }),
    columnHelper.accessor("enabled", {
      header: t("pages.members.tableColumns.status"),
      filterFn: "arrIncludesSome",
      meta: {
        filterType: "checkboxes",
      },
      cell: ({ row }) => (
        <Badge status={row.original.enabled ? "active" : "inactive"} />
      ),
    }),
    columnHelper.accessor("emailVerified", {
      header: t("pages.members.tableColumns.emailVerified"),
      cell: ({ row }) => (
        <Badge
          status={row.original.emailVerified ? "active" : "invited"}
          statusText={row.original.emailVerified ? "Verified" : "Waiting"}
        />
      ),
    }),
  ];

  const COColumns = [
    columnHelper.display({
      id: "actions",
      size: 50,
      cell: (row) => (
        <div className={styles.checkbox}>
          <input
            type="checkbox"
            onChange={() => addForDeletion(row.row)}
            checked={selectedRows.includes(row.row)}
          />
        </div>
      ),
    }),
    columns[0],
    columns[1],
    columns[2],
    columnHelper.accessor("groups", {
      header: t("pages.members.tableColumns.client"),
      meta: {
        filterType: "text",
      },
    }),
    ...columns.slice(3),
    columnHelper.display({
      id: "button",
      size: 50,
      cell: ({ row }) => (
        <Button
          icon={{ name: "pencil", intent: "black" }}
          intent="clear"
          onClick={() => setEditModalOpen(row.original)}
        />
      ),
    }),
  ];

  return COColumn ? COColumns : columns;
};
