import { Fragment } from "react";
import { useAuth } from "react-oidc-context";
import { Link, useNavigate } from "react-router-dom";

import { RInioButton } from "ui-core";

import { Badge } from "components/Badge";
import { DropdownUser } from "components/Dropdowns";
import { Icon } from "components/Icon";
import { Text } from "components/Text";

import styles from "./DashboardHeader.module.scss";

type Props = {
  title: string;
  subHeader?: string | JSX.Element;
  badge?: string;
  backBtn?: boolean;
  breadcrumbs?: { title: string; link?: string }[];
};

export function DashboardHeader({
  title,
  subHeader,
  badge,
  backBtn,
  breadcrumbs,
}: Props) {
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      // Edge case a user lands on a page and has no history stack
      navigate("/entities");
    }
  };

  return (
    <header className={styles.header}>
      <div className={styles.titleContainer}>
        {backBtn && (
          <RInioButton intent="clear" onClick={handleGoBack}>
            <Icon name="chevronLeft" size={14} />
          </RInioButton>
        )}
        <div>
          <h1>{title}</h1>
          <div className={styles.subHeader}>
            <span>
              <Text as={"p"} style={"body"} color="--content-subtle">
                {subHeader}
              </Text>
              {badge && <Badge status={badge} />}
            </span>

            {breadcrumbs && (
              <nav className={styles.breadcrumbs}>
                {breadcrumbs.map((item, index) => (
                  <Fragment key={index}>
                    <span key={item.title}>
                      {item.link ? (
                        <Link to={item.link}>
                          <Text
                            as={"p"}
                            style={"body"}
                            color="--content-subtle"
                          >
                            {item.title}
                          </Text>
                        </Link>
                      ) : (
                        <Text as={"p"} style={"body"} color="--content-subtle">
                          {item.title}
                        </Text>
                      )}
                    </span>
                    {index < breadcrumbs.length - 1 && (
                      <Text as={"p"} style={"body"} color="--content-subtle">
                        →
                      </Text>
                    )}
                  </Fragment>
                ))}
              </nav>
            )}
          </div>
        </div>
      </div>
      {user && (
        <DropdownUser title={user.profile.email} user={user} accounts={[]} />
      )}
    </header>
  );
}
