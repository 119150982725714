import { useTranslation } from "react-i18next";

import { Accordion } from "components/Accordion";
import { Button } from "components/Button";
import { Form } from "components/Form";
import { FormEntity } from "components/FormEntity";

import { AdditionalInfoForm } from "../AdditionalInfoForm";
import styles from "../WizardNewClaim.module.scss";

export function StepChooseEntity({ stepConfig }: any) {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.header}>
        <h2>{t("newClaim.createEntity")}</h2>
        <p>{t("newClaim.subHeader")}</p>
      </div>
      <Form {...stepConfig} id="form-entity" />
      <p className={styles.border}>or</p>
      <div className={styles.extrasContainer}>
        <Accordion title={t("newClaim.createNewEntity")}>
          <div className={styles.newEntityForm}>
            <FormEntity />
          </div>
        </Accordion>
        <Accordion title={t("newClaim.additionalInformation")}>
          <div className={styles.additionalInfoContainer}>
            <AdditionalInfoForm />
          </div>
        </Accordion>
        <Button
          text={t("continue")}
          intent="primary"
          type="submit"
          form="form-entity"
        />
      </div>
    </div>
  );
}
