import { createColumnHelper } from "@tanstack/react-table";

import { Pill } from "components/Pill";

import { getStageStatus } from "lib/helpers";

const columnHelper = createColumnHelper<Case>();

export const caseColumns = [
  columnHelper.accessor("customerName", {
    header: "Name",
    cell: (info) => info.getValue(),
    meta: {
      headerIcon: "usercirclesingle",
      filterType: "text",
    },
  }),
  columnHelper.accessor("customerNo", {
    header: "Customer No",
    cell: (info) => (
      <Pill
        text={info.row.original.customerNo}
        intent="Link"
        href={`/customer/${info.row.original.customerId}`}
      />
    ),
    meta: {
      headerIcon: "userprofilefocus",
      filterType: "text",
    },
  }),
  columnHelper.accessor("invoiceNo", {
    header: "Case ID",
    cell: (info) => (
      <Pill
        text={info.row.original.invoiceNo}
        intent="Link"
        href={`/customer/${info.row.original.customerId}}/${info.row.original.id}`}
      />
    ),
    meta: {
      headerIcon: "streamlineCoreLineFileText",
      filterType: "text",
    },
  }),
  columnHelper.accessor("receiverCountryCode", {
    header: "Country",
    cell: (info) => info.getValue(),
    meta: {
      headerIcon: "earth",
      filterType: "text",
    },
  }),
  columnHelper.accessor("dueDate", {
    header: "Due Date",
    cell: (info) => new Date(info.getValue()).toLocaleDateString(),
    meta: {
      headerIcon: "blankcalendar",
      filterType: "date",
    },
  }),
  columnHelper.accessor("totalAmount", {
    header: "TRB",
    cell: (info) => `${info.getValue()}`,
    meta: {
      headerIcon: "bagdollar",
      filterType: "text",
    },
  }),
  columnHelper.accessor("status", {
    id: "stage",
    header: "Stage",
    cell: (info) => (
      <p>
        {getStageStatus(info.row.original.status as InvoiceStatus).caseStage}
      </p>
    ),
    meta: {
      headerIcon: "stageClockHand",
      filterType: "dropdown",
    },
  }),
  columnHelper.accessor("status", {
    header: "Status",
    cell: (info) => (
      <p>
        {getStageStatus(info.row.original.status as InvoiceStatus).caseStatus}
      </p>
    ),
    meta: {
      headerIcon: "streamLineFileCheck",
      filterType: "dropdown",
    },
  }),
];
