import { ChangeEvent } from "react";

import { enumOptionsValueForIndex, WidgetProps } from "@rjsf/utils";

import { classNames } from "lib/classNames";

import styles from "./RadioWidget.module.scss";

export function RadioWidget(props: WidgetProps) {
  const {
    id,
    options,
    value,
    required,
    disabled,
    readonly,
    name,
    onChange,
    onBlur,
    onFocus,
  } = props;
  const { enumOptions, enumDisabled, emptyValue, inline } = options;

  const _onChange = (index: number) => {
    onChange(enumOptionsValueForIndex(index, enumOptions, emptyValue));
  };
  const _onBlur = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    onBlur(id, enumOptionsValueForIndex(value, enumOptions, emptyValue));
  const _onFocus = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    onFocus(id, enumOptionsValueForIndex(value, enumOptions, emptyValue));

  return (
    <div
      className={`${styles.radioContainer} ${
        inline ? styles.radioContainerInline : ""
      }`}
      tabIndex={0}
      aria-labelledby={id}
      role="radiogroup"
    >
      {Array.isArray(enumOptions) &&
        enumOptions.map((option, i) => {
          const itemDisabled =
            Array.isArray(enumDisabled) &&
            enumDisabled.indexOf(option.value) !== -1;

          return (
            <div
              key={i}
              className={classNames(styles.radio, {
                [styles.radioSelected]: value === option.value,
              })}
            >
              <input
                type="radio"
                id={props.id + option.value}
                value={option.value}
                name={props.id + name}
                required={required}
                disabled={disabled || itemDisabled || readonly}
                checked={value === option.value}
                onChange={() => _onChange(i)}
                onBlur={_onBlur}
                onFocus={_onFocus}
              />
              <label htmlFor={props.id + option.value}>{option.label}</label>
            </div>
          );
        })}
    </div>
  );
}
