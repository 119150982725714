import { CSSProperties } from "react";

import styles from "./Card.module.scss";

type CardProps = {
  children: React.ReactNode;
  style?: CSSProperties;
};

export function Card({ children, style }: CardProps) {
  return (
    <div className={styles.card} style={style}>
      {children}
    </div>
  );
}
