/**
 * The `classNames` function dynamically generates a string of class names for use in HTML or JSX.
 * It accepts multiple arguments of different types and concatenates them into a single string, omitting falsy values.
 *
 * Example:
 * classNames('btn', { [styles.active]: true, [styles.disabled]: false })
 * This would produce a string like 'btn active' depending on the truthiness of isActive, isDisabled.
 */
type ClassValue = string | number | Record<string, boolean> | undefined;

export function classNames(...args: ClassValue[]): string {
  return args
    .filter((arg): arg is ClassValue => Boolean(arg))
    .map((arg) => {
      if (typeof arg === "string" || typeof arg === "number") {
        return arg;
      } else if (Array.isArray(arg)) {
        return classNames(...arg);
      } else if (typeof arg === "object") {
        return Object.keys(arg)
          .filter((key) => arg[key])
          .join(" ");
      }
      return "";
    })
    .join(" ");
}
