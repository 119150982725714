import { ChangeEvent } from "react";

import { WidgetProps } from "@rjsf/utils";
import { RInioInput } from "ui-core";
import { Button } from "components/Button";

export function PasswordGenerateWidget(props: WidgetProps) {
  const {
    id,
    type,
    placeholder,
    onChange,
    onChangeOverride,
    onBlur,
    onFocus,
    value,
    uiSchema,
    schema,
    disabled,
    customUpdate
  } = props;

  const generatePassword = () => {
    const characters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$";

    const generatedPassword: string = Array.from(crypto.getRandomValues(new Uint32Array(8)))
      .map((x) => characters[x % characters.length])
      .join("");

    customUpdate(generatedPassword);
  };

  const onTextChange = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.(value === "" ? undefined : value.replace(" ", ""));
  };
  const onTextBlur = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement>) => onBlur?.(id, value);

  const onTextFocus = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement>) => onFocus?.(id, value);

  return (
    <div style={{ display: "flex" }}>
      <RInioInput
        style={{ width: "100%" }}
        id={id}
        name={props.name}
        type={type}
        disabled={schema?.readOnly || disabled ? true : false}
        placeholder={placeholder}
        value={value ?? ""}
        onChange={onChangeOverride || onTextChange}
        autoComplete={uiSchema?.["ui:autocomplete"]}
        onBlur={onTextBlur}
        onFocus={onTextFocus}
        editable={uiSchema?.["ui:editable"]}
        readonly={uiSchema?.["ui:editable"] || schema?.readOnly}
        maxLength={schema?.maxLength}
        minLength={schema?.minLength}
        link={
          uiSchema?.["ui:link"]
            ? {
              href: uiSchema["ui:link"].href,
              title: uiSchema["ui:link"].text,
            }
            : undefined
        }
      />

      <Button
        intent="black"
        icon={{ name: "key", intent: "white", size: 12 }}
        style={{ width: "45px", marginLeft: "10px", marginTop: "2px" }}
        onClick={generatePassword}
      />
    </div>
  );
}