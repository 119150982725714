import { useTranslation } from "react-i18next";

import { Form } from "components/Form";

import styles from "../WizardNewClaim.module.scss";

type Props = {
  stepConfig: {
    uiSchema: {
      claims: {
        name: string;
        entityId: string;
      };
    };
  };
  previousStepData: {
    entity: any;
  };
};

export function StepCreateClaim({ stepConfig, previousStepData }: Props) {
  const { t } = useTranslation();

  stepConfig.uiSchema.claims.name = previousStepData.entity;
  stepConfig.uiSchema.claims.entityId = "12345678";

  return (
    <div>
      <div className={styles.header}>
        <h2>{t("newClaim.createClaim")}</h2>
        <p>{t("newClaim.subHeader")}</p>
      </div>
      <Form
        {...stepConfig}
        customValidate={(formData, errors) => {
          if (
            new Date(formData.claims[0].occurenceDate) >=
            new Date(formData.claims[0].dueDate)
          ) {
            errors.claims[0].dueDate.addError(
              t("newClaim.dueDateLestThanOccurenceDate"),
            );
          }

          return errors;
        }}
      />
    </div>
  );
}
