import { ChangeEvent, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { RInioInput, MaxLength, Pattern } from "ui-core";

import { ModalAction } from "components/Modal";

import { buildAddressString } from "lib/helpers";

import styles from "./CustomerProfileInformation.module.scss";

type Address = {
  city: string;
  street: string;
  postcode: string;
};

type Props = {
  email: string;
  phone: string;
  country: string;
  address: Address;
};

export function CustomerProfileInformation({
  email,
  phone,
  country,
  address,
}: Props) {
  const { t } = useTranslation();
  const addressString = buildAddressString({
    city: address.city,
    street: address.street,
    postcode: address.postcode,
  });

  const [formData, setFormData] = useState({
    email,
    phone,
    country,
    address: addressString,
    // Custom web component expects errors as string[], so the errors here will adhere to that pattern
    emailErrors: [],
    phoneErrors: [],
    countryErrors: [],
    addressErrors: [],
  });
  const [showModal, setShowModal] = useState({
    isOpen: false,
    associatedValue: "",
    associatedKey: "",
  });

  const emailRegexp = useRef(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  );
  const handleValidateInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      if (name === "email") {
        if (!emailRegexp.current.test(value)) {
          return [t("pages.customer.errors.email.pattern")];
        } else {
          return value === ""
            ? [t("pages.customer.errors.email.required")]
            : [];
        }
      } else {
        return value === ""
          ? [t(`pages.customer.errors.${name}.required`)]
          : [];
      }
    },
    [],
  );

  // Handle update form data
  const handleUpdateFormData = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFormData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
        [`${e.target.name}Errors`]: handleValidateInput(e),
      }));
    },
    [email, phone, country, address],
  );

  // Handle show/close modal form
  const handleShowModalForm = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setShowModal((prev) => ({
        isOpen: true,
        associatedValue: e.target.value,
        associatedKey: e.target.name,
      }));
    },
    [formData],
  );
  const handleCloseModalForm = useCallback(() => {
    setShowModal((prev) => ({
      ...prev,
      isOpen: false,
    }));
  }, []);

  // Handle save changes
  const handleSaveChanges = useCallback(() => {
    alert(
      `The new updated ${showModal.associatedKey} is ${showModal.associatedValue}`,
    );
    handleCloseModalForm();
  }, [showModal.associatedValue]);

  return (
    <div className={styles.editForm}>
      <div>
        <RInioInput
          errors={formData.emailErrors}
          readOnly
          name="email"
          value={formData.email}
          placeholder="johndoe@example.com"
          label={t("pages.customer.customerInformationForm.email")}
          onSave={handleShowModalForm}
          onChange={handleUpdateFormData}
          validateOnChange={true}
          validators={[
            new MaxLength(50, {
              getMessage: () => t("pages.customer.errors.email.maxLength"),
            }),
          ]}
        />
      </div>
      <div>
        <RInioInput
          errors={formData.phoneErrors}
          name="phone"
          value={formData.phone}
          readOnly
          placeholder={t("pages.customer.customerInformationForm.phone")}
          label={t("pages.customer.customerInformationForm.phone")}
          onSave={handleShowModalForm}
          onChange={handleUpdateFormData}
          validateOnChange={true}
          validators={[
            new MaxLength(18, {
              getMessage: () => t("pages.customer.errors.phone.maxLength"),
            }),
            new Pattern(new RegExp("^\\d*\\s*$"), {
              getMessage: () => t("pages.customer.errors.phone.pattern"),
            }),
          ]}
        />
      </div>
      <div>
        <RInioInput
          errors={formData.countryErrors}
          name="country"
          value={formData.country}
          readOnly
          placeholder={t("pages.customer.customerInformationForm.country")}
          label={t("pages.customer.customerInformationForm.country")}
          onSave={handleShowModalForm}
          onChange={handleUpdateFormData}
          validateOnChange={true}
          validators={[
            new MaxLength(50, {
              getMessage: () => t("pages.customer.errors.country.maxLength"),
            }),
          ]}
        />
      </div>
      <div>
        <RInioInput
          errors={formData.addressErrors}
          name="address"
          value={formData.address}
          readOnly
          placeholder={t("pages.customer.customerInformationForm.address")}
          label={t("pages.customer.customerInformationForm.address")}
          onSave={handleShowModalForm}
          onChange={handleUpdateFormData}
          validateOnChange={true}
          validators={[
            new MaxLength(50, {
              getMessage: () => t("pages.customer.errors.address.maxLength"),
            }),
          ]}
        />
      </div>

      <ModalAction
        direction="column"
        isOpen={showModal.isOpen}
        onClose={handleCloseModalForm}
        intent="danger"
        title={t("pages.customer.attention")}
        content={() => (
          <div>
            {t("pages.customer.areYouSureAboutChanging")}&nbsp;
            <strong>{showModal.associatedKey}</strong>?
          </div>
        )}
        ctaBtns={[
          {
            text: t("close"),
            intent: "primary-light",
            onClick: handleCloseModalForm,
          },
          {
            text: t("pages.customer.saveChanges"),
            onClick: () => handleSaveChanges(),
            intent: "black",
          },
        ]}
      />
    </div>
  );
}
