import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";

export function PageAuthCallback() {
  const auth = useAuth();

  const navigate = useNavigate();

  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
  }

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.user) {
    navigate("/");
  }
}
