import { classNames } from "lib/classNames";

import userIcon from "../../assets/icons/User-80.svg";
import styles from "./Avatar.module.scss";

type Props = {
  profileImage?: string;
  userName: string;
  toShowUsername: boolean;
  size?: "large" | "medium" | "small";
};

export function Avatar({
  profileImage,
  userName,
  toShowUsername = false,
  size = "large",
}: Props) {
  if (!profileImage) {
    profileImage = userIcon;
  }

  return (
    <div className={styles.avatarWrapper}>
      <img
        className={classNames(styles.img, styles[size])}
        src={profileImage}
        alt={userName}
        title={userName}
      />

      {toShowUsername && <h3 className={styles.userName}>{userName}</h3>}
    </div>
  );
}
