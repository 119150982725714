import { createSlice } from "@reduxjs/toolkit";

import { appStorage } from "lib/localStorage";

import i18n from "utils/i18n";

export const languageSlice = createSlice({
  name: "language",
  initialState: {
    language: i18n.language,
  },
  reducers: {
    setLanguage: (state, { payload: language }) => {
      appStorage.setItem("language", language);
      i18n.changeLanguage(language);
      const html = document.querySelector("html");
      html?.setAttribute("lang", language);
      state.language = language;
    },
  },
});

export const { setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
