import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { LayoutDashboard } from "components/LayoutDashboard";
import { PrivateRoute } from "components/PrivateRoute";

import { setLanguage } from "lib/slices/languageSlice";

import i18n from "utils/i18n";

import { Docs } from "pages/Docs";
import { PageAuthCallback } from "pages/auth/Login/PageLogin";

const App = () => {
  const dispatch = useDispatch();

  // Set html lang attribute
  useEffect(() => {
    dispatch(setLanguage(i18n.language));
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          children={<Route path="/callback" element={<PageAuthCallback />} />}
        />

        <Route children={<Route path="/docs" element={<Docs />} />} />

        <Route
          path="/*"
          element={<PrivateRoute children={<LayoutDashboard />} />}
        />
      </Routes>
    </Router>
  );
};

export default App;
