import { FieldErrorProps } from "@rjsf/utils";

import styles from "./FieldErrorTemplate.module.scss";

/**
 * Error messages for individual form fields
 * https://rjsf-team.github.io/react-jsonschema-form/docs/advanced-customization/custom-templates#fielderrortemplate
 */
export function FieldErrorTemplate(props: FieldErrorProps) {
  const { errors } = props;

  return (
    <div className={styles.errorsContainer}>
      {errors && errors.map((error, index) => <p key={index}>{error}</p>)}
    </div>
  );
}
