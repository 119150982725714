import { forwardRef, useEffect, useRef, useState } from "react";
import ReactDatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";

import { classNames } from "lib/classNames";
import { formatDateRange, formatDateToYYYYMMDD } from "lib/dateUtils";

import "./DateWidget.scss";

interface DateWidgetProps {
  value?: [Date | undefined, Date | undefined] | Date;
  onChange: (value: any) => void;
  onSetDate?: () => void;
  range?: boolean;
  inline?: boolean;
  options?: {
    wide?: boolean;
    fillSpace?: boolean;
    intent?: "primary" | "black";
    buttonsLayout?: Array<"cancel" | "select-today" | "set-date" | "close">;
  };
  rawErrors?: string[];
}

export function DateWidget({
  value,
  onChange,
  onSetDate,
  range = false,
  inline = false,
  options = {},
  rawErrors = [],
}: DateWidgetProps) {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    Array.isArray(value) ? value?.[0] : value,
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Date | undefined>(
    Array.isArray(value) ? value?.[1] : undefined,
  );
  const { t } = useTranslation();

  const ref = useRef<ReactDatePicker>(null);

  useEffect(() => {
    if (Array.isArray(value)) {
      setSelectedDate(value?.[0]);
      setSelectedEndDate(value?.[1]);
    }
  }, [value]);

  const _onChange = (value: [Date, Date] | Date | null) => {
    if (range && Array.isArray(value)) {
      const [start, end] = value;

      if (new Date(start).toDateString() === new Date(end).toDateString()) {
        return;
      }

      setSelectedDate(start);
      setSelectedEndDate(end);
      onChange({ startDate: start, endDate: end });
    } else if (value instanceof Date) {
      setSelectedDate(value);
      onChange(formatDateToYYYYMMDD(value));
    }
  };

  const onClick = () => {
    ref.current?.setOpen(false);
    onSetDate?.();
  };

  const resetDate = () => {
    setSelectedDate(undefined);
    setSelectedEndDate(undefined);
    onChange({ startDate: undefined, endDate: undefined });
    ref.current?.setOpen(false);
  };

  const calendarContainer = ({ className, children }: any) => (
    <div
      className={classNames(
        "datePickerContainer",
        options.intent !== "primary"
          ? `${options.intent}DatePickerContainer`
          : "",
      )}
    >
      <CalendarContainer className={className}>{children}</CalendarContainer>
    </div>
  );

  const renderCustomHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: {
    date: Date;
    decreaseMonth: () => void;
    increaseMonth: () => void;
    prevMonthButtonDisabled: boolean;
    nextMonthButtonDisabled: boolean;
  }) => (
    <div className="datePickerHeader">
      <Button
        disabled={prevMonthButtonDisabled}
        icon={{ name: "chevronRight", size: 12 }}
        onClick={decreaseMonth}
      />
      <p>
        {date.toLocaleString("default", { month: "long", year: "numeric" })}
      </p>
      <Button
        disabled={nextMonthButtonDisabled}
        icon={{ name: "chevronRight", size: 12 }}
        onClick={increaseMonth}
      />
    </div>
  );

  if (ref.current && options.fillSpace) {
    (
      ref.current?.input?.closest(".react-datepicker-wrapper") as HTMLElement
    ).style.display = "block";
  }

  const DatePickerInput = forwardRef<HTMLDivElement, { onClick?: () => void }>(
    ({ onClick }, ref) => (
      <div
        ref={ref}
        className={classNames(
          options?.wide ? "datePickerInput" : undefined,
          options?.fillSpace ? "datePickerContainerFillSpace" : undefined,
        )}
      >
        <Button
          icon={{ name: "blankcalendar", intent: "black" }}
          onClick={onClick}
          intent={selectedDate ? "primary-light" : "default"}
          text={
            (selectedDate &&
              selectedEndDate &&
              formatDateRange(selectedDate, selectedEndDate)) ||
            (selectedDate && formatDateToYYYYMMDD(selectedDate))
          }
          hasError={rawErrors.length > 0}
        />
      </div>
    ),
  );
  DatePickerInput.displayName = "DatePickerInput";

  return (
    <ReactDatePicker
      ref={ref}
      selected={selectedDate}
      startDate={selectedDate}
      endDate={selectedEndDate}
      highlightDates={selectedEndDate ? [new Date(selectedEndDate)] : []}
      onChange={_onChange}
      shouldCloseOnSelect={false}
      renderCustomHeader={renderCustomHeader}
      calendarContainer={calendarContainer}
      customInput={<DatePickerInput />}
      // @ts-expect-error strange type error
      selectsRange={range}
      inline={inline}
    >
      <div className="btnContainer">
        {options.buttonsLayout?.length === 0 && (
          <>
            <Button text={t("cancel")} onClick={resetDate} fill />
            <Button
              text={t("dateWidget.setDate")}
              fill
              disabled={!selectedDate}
              intent={
                selectedDate
                  ? options.intent
                    ? options.intent
                    : "primary"
                  : "default"
              }
              onClick={onClick}
              icon={
                selectedDate
                  ? { name: "check", size: 14, intent: "white" }
                  : undefined
              }
            />
          </>
        )}

        {/* If there is a buttonsLayout prop */}
        {options.buttonsLayout &&
          options.buttonsLayout.map((el) => {
            if (el === "cancel") {
              return (
                <Button key={el} text={t("cancel")} onClick={resetDate} fill />
              );
            }

            if (el === "close") {
              return (
                <Button
                  key={el}
                  text={t("close")}
                  onClick={() => ref.current?.setOpen(false)}
                  fill
                />
              );
            }

            if (el === "select-today") {
              return (
                <Button
                  key={el}
                  text="Select today"
                  intent={options.intent ? options.intent : "primary"}
                  onClick={() => {
                    _onChange(new Date());
                  }}
                  fill
                />
              );
            }

            if (el === "set-date") {
              <Button
                key={el}
                text={t("dateWidget.setDate")}
                fill
                disabled={!selectedDate}
                intent={
                  selectedDate
                    ? options.intent
                      ? options.intent
                      : "primary"
                    : "default"
                }
                onClick={onClick}
                icon={
                  selectedDate
                    ? { name: "check", size: 14, intent: "white" }
                    : undefined
                }
              />;
            }
          })}
      </div>
    </ReactDatePicker>
  );
}
